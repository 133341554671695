import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import routesConfig from '~/config/routes';
import logo from '~/assets/images/logo-page.png';
import avatarAdmin from '~/assets/images/avatar-admin.jpg';
import avatarUser from '~/assets/images/avatar-user.jpg';
import { FiLogIn } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { logout, useAuthDispatch, useAuthState } from '~/context';

const cx = classNames.bind(styles);

function Header() {
    const dispatch = useAuthDispatch();
    let navigate = useNavigate();

    const userDetails = useAuthState();

    const handleLogout = async () => {
        await logout(dispatch);
        navigate(routesConfig.login);
    };

    return (
        <>
            <div className={cx('loading')}></div>
            <div className={cx('wrapper', 'glo-width-header')}>
                <header className={cx('wrapper-menu', 'd-flex')}>
                    <nav className={cx('menu-horizontal-header')}>
                        <Link to={routesConfig.home} className={cx('menu-horizontal-header__thumnail')}>
                            <img src={logo} alt="logo-header" />
                        </Link>
                    </nav>

                    {/* menu user */}
                    <div className={cx('menu-user')}>
                        <div className={cx('menu-user__item')}>
                            <div className={cx('menu-user__item-text')}>
                                <span>{userDetails.userDetails.user_name}</span>
                                <span className={cx('menu-user__item-text-policy')}>
                                    {userDetails.userDetails.role === 'Admin' ? 'アドミン' : 'ユーザー'}
                                </span>
                            </div>
                            <div className={cx('dropdown')}>
                                <button
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className={cx('btn-avatar')}
                                >
                                    <img
                                        src={userDetails.userDetails.role === 'Admin' ? avatarAdmin : avatarUser}
                                        className={cx('avatar-user')}
                                        alt="Avatar"
                                    />
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    id={cx('dropdown-menu__avatar')}
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    {/* <li className={cx('my-profile')}>
                                        <Link to="/profile">
                                            <CgProfile className={cx('my-profile__icon')} fontSize={'18px'} />
                                            <span className={cx('header-text-span')}>Profile</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li> */}

                                    <li className={cx('my-profile')} onClick={handleLogout}>
                                        <div className={cx('wrap-li')}>
                                            <FiLogIn className={cx('my-profile__icon')} fontSize={'18px'} />
                                            <button>ログアウト</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
}

export default Header;
