import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import styles from './UserList.module.scss';
import { IoIosArrowDown, IoMdAddCircleOutline } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { useMyContext } from '~/context/store';
import Swal from 'sweetalert2';
import FormModal from '~/components/FormModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDebounce from '~/hooks/useDebounce';
import moment from 'moment';
import BreadcrumbsUser from '~/components/BreadcrumbsUser';

const cx = classNames.bind(styles);

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

function UserList() {
    const [idsChecked, setIdsChecked] = useState([]);
    const { loading, setLoading } = useMyContext();
    const [users, setUsers] = useState([]);
    const [callback, setCallback] = useState(false);
    const [isShowFormEdit, setIsShowFormEdit] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalUsers, setTotalUsers] = useState(null);

    const [isShowMenuFilter, setIsShowMenuFilter] = useState(false);
    const [isShowMenuDropdown, setIsShowMenuDropdown] = useState(false);
    const [groupIds, setGroupIds] = useState([]);
    const [search, setSearch] = useState();
    const [filterGroup, setFilterGroup] = useState(0);
    const [infoUsers, setInfoUsers] = useState([]);

    const refFilter = useRef();

    const { id } = useParams();

    const searchDebounce = useDebounce(search, 600);
    const filterDebounce = useDebounce(filterGroup, 400);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleAddId = (user) => {
        setIdsChecked([...idsChecked, user.id]);

        if (infoUsers.includes(user)) {
            setInfoUsers(infoUsers.filter((y) => y.id !== user.id));
        } else {
            setInfoUsers([...infoUsers, user]);
        }

        if (idsChecked.includes(user.id)) {
            setIdsChecked(idsChecked.filter((y) => y !== user.id));
        }
    };

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true);

            const params = {
                offset,
                limit,
                searchTerm: search,
                groupManageId: id,
            };
            const res = await api.get('api/users/filters', { params });

            if (res.status === 200) {
                setUsers(res.data.data.data);
                setTotalUsers(res.data.meta.total);
                setLoading(false);
            }
        };
        getUsers();
    }, [callback, searchDebounce, filterDebounce]);

    const handleClickOutside = (event) => {
        if (refFilter.current && !refFilter.current.contains(event.target)) {
            setIsShowMenuFilter(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isShowMenuFilter]);

    useEffect(() => {
        const getUsers = async () => {
            const params = {
                offset,
                limit,
            };

            const res = await api.get('api/users/filters', { params });
            if (res.status === 200) {
                setUsers([...users, ...res.data.data.data]);
            }
        };
        offset >= 16 && getUsers();
    }, [offset]);

    useEffect(() => {
        const getGroups = async () => {
            const res = await api.get('api/groupManages');

            if (res.status === 200) {
                setGroupIds(res.data.data);
            }
        };
        if (groupIds.length === 0) {
            getGroups();
        }
    }, []);

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleAddUser = () => {
        setIsShowFormEdit(true);
        setDataForm({
            type: 'add',
            titleForm: 'Add User',
            text: 'ユーザー追加',
            data: [
                { value: '', title: 'ユーザー名', name: 'user_name', isShow: true },
                { value: '', title: 'パスワード', name: 'password', isShow: true },
                {
                    value: '',
                    title: '通知',
                    name: 'can_notify',
                    isShow: true,
                    min: 0,
                    max: 1,
                    placeholder: '0：送信しない、1：送信する',
                },
                { value: '', title: 'メールアドレス', name: 'mail_address', isShow: true },
                { value: '', title: '電話番号', name: 'tel_no', isShow: true },
                { value: '', title: 'Manage Gid', name: 'manage_gid', type: 'select', isShow: true },
            ],
        });
    };

    const handleEditUser = () => {
        const user = users.find((ele) => ele.id === idsChecked[0]);
        setIsShowFormEdit(true);
        setDataForm({
            type: 'edit',
            titleForm: 'Edit Info User',
            text: 'ユーザー編集',
            id: user.id,
            data: [
                { value: user.user_name, title: 'ユーザー名', name: 'user_name', isShow: true },
                {
                    value: user.can_notify,
                    title: '通知',
                    name: 'can_notify',
                    isShow: true,
                    min: 0,
                    max: 1,
                    placeholder: '0：送信しない、1：送信する',
                },
                { value: user.mail_address, title: 'メールアドレス', name: 'mail_address', isShow: true },
                { value: user.tel_no, title: '電話番号', name: 'tel_no', isShow: true },
                { value: user.groupManage.id, title: 'Manage Gid', name: 'manage_gid', type: 'select', isShow: true },
            ],
        });
    };

    const handleDeleteUser = async () => {
        Swal.fire({
            title: '確認',
            text: `ユーザーを削除しますか？`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            cancelButtonText: 'キャンセル',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await Promise.all(
                    idsChecked.map(async (id) => {
                        await api.delete(`api/users/${id}`);
                    }),
                );
                setCallback(!callback);
                setIdsChecked([]);
                setOffset(0);
                Swal.fire('通知', `ユーザーを削除しました`, 'success');
            }
        });
    };

    const handleClickDropdown = async () => {
        setIsShowMenuFilter(!isShowMenuFilter);
    };

    const handleSearchUser = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    const handleFilterGroup = (id) => {
        if (filterGroup !== id) {
            setOffset(0);
            setFilterGroup(id);
        }
    };

    const handleFilterGroupNone = () => {
        setFilterGroup(0);
    };

    return (
        <>
            <div className={cx('wrap-header')}>
                <div className={cx('wrap-header-title')}>
                    {/* <BreadcrumbsUser pathCurrent={`/groupManages/${id}`} /> */}

                    {/* button action */}
                    <div className={cx('wrap-btn-action')}>
                        <input className={cx('input-search')} placeholder="ユーザー検索" onChange={handleSearchUser} />
                        <button className={cx('btn-action', 'btn-action-add')} onClick={handleAddUser}>
                            <IoMdAddCircleOutline className={cx('btn-action__icon-add')} />
                            <span className={cx('btn-action__name', 'btn-action__name-add')}>ユーザー追加</span>
                        </button>
                        <button
                            className={cx('btn-action', 'btn-action-edit')}
                            onClick={() => handleEditUser()}
                            disabled={idsChecked.length !== 1}
                        >
                            <MdModeEditOutline className={cx('btn-action__icon-edit')} />
                            <span className={cx('btn-action__name', 'btn-action__name-edit')}>編集</span>
                        </button>
                        <button
                            className={cx('btn-action', 'btn-action-delete')}
                            disabled={idsChecked.length < 1}
                            onClick={handleDeleteUser}
                        >
                            <AiFillDelete className={cx('btn-action__icon-delete')} />
                            <span className={cx('btn-action__name', 'btn-action__name-delete')}>削除</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={cx('scroll-outer-wrapper')}>
                <div className={cx('scroll-outer')}>
                                        
                    <div className={cx('wrap-table')} style={{ overflowX: loading ? 'hidden' : 'auto', height : 'calc(82vh - 40px)'}} >
                        <div className={cx('list-table')}>
                            <div className={cx('list-title-table')}>
                                <div className={cx('glo-table-col-checkbox')}></div>
                                {/* <div className={cx('glo-table-col-id', 'glo-table-col-name')}>ID</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ユーザー名</div>
                                <div className={cx('glo-table-col-notify', 'glo-table-col-name', 'text-left')}>通知</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>メールアドレス</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>電話番号</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>管理グループ</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>作成日</div>
                            </div>

                            <div className={cx('infinite-scroll-container')}>
                                {!loading && (
                                    <InfiniteScroll
                                        dataLength={users.length}
                                        next={fetchData}
                                        hasMore={users.length >= totalUsers ? false : true}
                                        loader={offset >= 16 && <h4>読み込み中...</h4>}
                                        // endMessage={
                                        //     offset >= 12 && (
                                        //         <p style={{ textAlign: 'center' }}>
                                        //             <b>Yay! You have seen it all</b>
                                        //         </p>
                                        //     )
                                        // }
                                    >
                                        {users.length > 0
                                            ? users.map((user) => (
                                                  <div
                                                      className={cx('list-item-body-tr')}
                                                      key={user.id}
                                                      onClick={() => handleAddId(user)}
                                                  >
                                                      <div className={cx('glo-table-col-checkbox')}>
                                                          <input
                                                              type="checkbox"
                                                              checked={idsChecked.includes(user.id)}
                                                          />
                                                      </div>
                                                      {/* <div className={cx('glo-table-col-id')}>{user.id}</div> */}
                                                      <div className={cx('glo-table-col', 'text-left')}>{user.user_name}</div>
                                                      <div className={cx('glo-table-col-notify', 'text-left')}>
                                                          {user.can_notify === 0 ? '無' : '有'}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>{user.mail_address}</div>
                                                      <div className={cx('glo-table-col', 'text-left')}>{user.tel_no}</div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {user.groupManage.group_name}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {user.created_at &&
                                                              moment(user.created_at).format('YYYY-MM-DD')}
                                                      </div>
                                                  </div>
                                              ))
                                            : totalUsers !== null && <p className={cx('no-match')}>結果を見つけない</p>}
                                    </InfiniteScroll>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FormModal
                isOpen={isShowFormEdit}
                setIsOpen={setIsShowFormEdit}
                data={dataForm}
                setData={setDataForm}
                callback={callback}
                setCallback={setCallback}
                setIdsChecked={setIdsChecked}
                setInfoUsers={setInfoUsers}
                setOffset={setOffset}
            />
        </>
    );
}

export default UserList;
