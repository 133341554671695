import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './ServerList.module.scss';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { useMyContext } from '~/context/store';
import moment from 'moment';
import FormModal from '~/components/FormModal';
import Swal from 'sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import Breadcrumbs from '~/components/Breadcrumbs';
import useDebounce from '~/hooks/useDebounce';

const cx = classNames.bind(styles);

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

function ServerList() {
    const [idsChecked, setIdsChecked] = useState([]);
    const [servers, setServers] = useState([]);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const { loading, setLoading, breadcrumbs, setBreadcrumbs } = useMyContext();
    const [callback, setCallback] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalServers, setTotalServers] = useState(null);
    const [search, setSearch] = useState('');

    const searchDebounce = useDebounce(search, 600);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const getAllServers = async () => {
            setLoading(true);
            const params = {
                offset,
                limit,
                searchTerm: search,
            };
            const res = await api.get(`api/servers/filters`, { params });
            console.log(res)
            if (res.status === 200) {
                setServers(res.data.data.data);
                setTotalServers(res.data.meta.total);
                setLoading(false);
            }
        };

        getAllServers();
    }, [callback, searchDebounce]);

    useEffect(() => {
        const getGroupServers = async () => {
            const params = {
                offset,
                limit,
                groupSurveillanceIds: [id],
            };
            const res = await api.get('api/servers/filters', { params });
            if (res.status === 200) {
                setServers([...servers, ...res.data.data.data]);
            }
        };
        offset >= 16 && getGroupServers();
    }, [offset]);

    const handleAddId = (e, id) => {
        e.stopPropagation();
        setIdsChecked([...idsChecked, id]);

        if (idsChecked.includes(id)) {
            setIdsChecked(idsChecked.filter((y) => y !== id));
        }
    };

    const handleRedirect = () => {
        navigate(routesConfig.home);
    };

    const handleRedirectUrl = (id, name) => {
        setBreadcrumbs([
            // { path: '/serverList', breadcrumb: 'グループサーバー管理' },
            // { path: `/group/${id}`, breadcrumb: name }
        ]);
        navigate(`/server/${id}`);
    };

    const handleAddServer = () => {
        setIsOpenForm(true);
        setDataForm({
            type: 'add',
            titleForm: 'Add Server',
            text: 'サーバー追加',
            data: [
                { value: '', title: 'サーバー名', name: 'server_name', isShow: true },
                { value: [], title: 'グループ管理名', name: 'manage_gid', type: 'select', isShow: true },
                { value: '', title: 'IPアドレス', name: 'ip_address', isShow: true },
                { value: '', title: 'Surveillance Gid', name: 'surveillance_gid', type: 'select', isShow: true },
                { value: '', title: 'ロケーション', name: 'location', isShow: true },
                { value: '', title: 'マシン名', name: 'machine_name', isShow: true },
                { value: '', title: 'OS', name: 'os', isShow: true },
                { value: '', title: 'ディストリビューション', name: 'distribution', isShow: true },
                { value: '', title: 'バージョン', name: 'version', isShow: true },
                {
                    value: '',
                    title: '停止中',
                    name: 'suspended',
                    isShow: true,
                    min: 0,
                    max: 1,
                    placeholder: '0: オン、1: オフ',
                },
                { value: '', title: '停止中時間', name: 'suspension_time', isShow: true },
                { value: 0, title: 'ステータス', name: 'state', isShow: false, min: 0, max: 3 },
            ],
        });
    };

    const handelDeleteServer = () => {
        Swal.fire({
            title: '確認',
            text: `サーバーを削除しますか？`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            cancelButtonText: 'キャンセル',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await Promise.all(
                    idsChecked.map(async (id) => {
                        await api.delete(`api/servers/${id}`);
                    }),
                );
                setCallback(!callback);
                setIdsChecked([]);
                setOffset(0);
                Swal.fire('通知', `サーバーを削除しました`, 'success');
            }
        });
    };

    const handleEditServer = () => {
        const groupServerActive = servers.find((ele) => ele.id === idsChecked[0]);
        const groupManagesIds = groupServerActive.groupManages.map((group) => group.id);
        const groupSurveillancesId = servers[0].groupSurveillance.id;

        setIsOpenForm(true);
        setDataForm({
            type: 'edit',
            titleForm: 'Edit Server',
            text: 'サーバー編集',
            id: idsChecked[0],
            data: [
                { value: groupServerActive.server_name, title: 'サーバー名', name: 'server_name', isShow: true },
                { value: groupManagesIds, title: '管理グループ名', name: 'manage_gid', type: 'select', isShow: true },
                { value: groupServerActive.ip_address, title: 'IPアドレス', name: 'ip_address', isShow: true },
                {
                    value: groupSurveillancesId,
                    title: 'Surveillance Gid',
                    name: 'surveillance_gid',
                    type: 'select',
                    isShow: true,
                },
                { value: groupServerActive.location, title: 'ロケーション', name: 'location', isShow: true },
                { value: groupServerActive.machine_name, title: 'マシン名', name: 'machine_name', isShow: true },
                { value: groupServerActive.os, title: 'OS', name: 'os', isShow: true },
                {
                    value: groupServerActive.distribution,
                    title: 'ディストリビューション',
                    name: 'distribution',
                    isShow: true,
                },
                { value: groupServerActive.version, title: 'バージョン', name: 'version', isShow: true },
                {
                    value: groupServerActive.suspended,
                    title: '停止中',
                    name: 'suspended',
                    isShow: true,
                    min: 0,
                    max: 1,
                    placeholder: '0: オン、1: オフ',
                },
                {
                    value: moment(groupServerActive.suspension_time).format('YYYY-MM-DD'),
                    title: '停止中時間',
                    name: 'suspension_time',
                    isShow: groupServerActive.suspended === 0 ? true : false,
                },
                { value: groupServerActive.state, title: 'ステータス', name: 'state', isShow: false, min: 0, max: 3 },
            ],
        });
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleSearch = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    const getStatus = (status) => {
        if (status === 0) {
            return <div className={cx('status-ok')}>OK</div>;
        } else if (status === 1) {
            return <div className={cx('status-stop')}>チェック中</div>;
        } else if (status === 2) {
            return <div className={cx('status-warning')}>警告</div>;
        } else if (status === 3) {
            return <div className={cx('status-error')}>エラー</div>;
        }
    };

    return (
        <>
            <div className={cx('wrap-header')}>
                <div className={cx('wrap-header-title')}>
                    {/* <div className={cx('Breadcrumbs')}>
                        <span className={cx('Breadcrumbs_name')} onClick={handleRedirect}>Group server 1</span>
                        <span className={cx('icon-next')}>{'>'}</span>
                        <span className={cx('Breadcrumbs_name-disable')}>Server 3</span>
                    </div> */}
                    {/* <Breadcrumbs pathCurrent={`/group/${id}`} /> */}

                    {/* button action */}
                    <div className={cx('wrap-btn-action')}>
                        <input className={cx('input-search')} placeholder="サーバー検索" onChange={handleSearch} />
                        <button className={cx('btn-action', 'btn-action-add')} onClick={handleAddServer}>
                            <IoMdAddCircleOutline className={cx('btn-action__icon-add')} />
                            <span className={cx('btn-action__name', 'btn-action__name-add')}>サーバー追加</span>
                        </button>

                        <button
                            className={cx('btn-action', 'btn-action-edit')}
                            disabled={idsChecked.length !== 1}
                            onClick={handleEditServer}
                        >
                            <MdModeEditOutline className={cx('btn-action__icon-edit')} />
                            <span className={cx('btn-action__name', 'btn-action__name-edit')}>編集</span>
                        </button>
                        <button
                            className={cx('btn-action', 'btn-action-delete')}
                            disabled={idsChecked.length < 1}
                            onClick={handelDeleteServer}
                        >
                            <AiFillDelete className={cx('btn-action__icon-delete')} />
                            <span className={cx('btn-action__name', 'btn-action__name-delete')}>削除</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={cx('scroll-outer-wrapper')}>
                <div className={cx('scroll-outer')}>
                    <div className={cx('wrap-table')} style={{ overflowX: loading ? 'hidden' : 'auto', height : 'calc(82vh - 40px)' }}>
                        <div className={cx('list-table')}>
                            <div className={cx('list-title-table')}>
                                <div className={cx('glo-table-col-checkbox')}></div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>ID</div> */}
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>サーバー名</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>IPアドレス</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ステータス</div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>管理グループ</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>Surveillance Gid</div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ロケーション</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>マシン名</div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>OS</div> */}
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ディストリビューション</div> */}
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-version', 'text-left')}>
                                    バージョン
                                </div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-stop', 'text-left')}>
                                    停止中
                                </div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>停止中時間</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>作成日</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>スキャン時刻</div>
                            </div>

                            {!loading && (
                                <div className={cx('infinite-scroll-container')}>
                                    <InfiniteScroll
                                        dataLength={servers.length}
                                        next={fetchData}
                                        hasMore={servers.length >= totalServers ? false : true}
                                        loader={<h4>読み込み中...</h4>}
                                    >
                                        {servers.length > 0
                                            ? servers.map((server) => (
                                                  <div
                                                      className={cx('list-item-body-tr')}
                                                      key={server.id}
                                                      onClick={() => handleRedirectUrl(server.id, server.server_name)}
                                                  >
                                                      <div
                                                          className={cx('glo-table-col-checkbox')}
                                                          onClick={(e) => handleAddId(e, server.id)}
                                                      >
                                                          <input
                                                              type="checkbox"
                                                              checked={idsChecked.includes(server.id)}
                                                          />
                                                      </div>
                                                      {/* <div className={cx('glo-table-col')}>{server.id}</div> */}
                                                      {/* <div className={cx('glo-table-col', 'glo-table-col__server')}>
                                                          <span>{server.server_name}</span>
                                                      </div> */}
                                                      <div className={cx('glo-table-col', 'text-left')}>{server.ip_address}</div>
                                                      <div className={cx('glo-table-col', 'glo-table-col__status')}>
                                                          {getStatus(server.state)}
                                                      </div>
                                                      {/* <div className={cx('glo-table-col')}>
                                                          {server.groupManages
                                                              .map((group, index) => group.group_name)
                                                              .join(', ')}
                                                      </div> */}
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.groupSurveillance.group_name}
                                                      </div>
                                                      {/* <div className={cx('glo-table-col')}>{server.location}</div> */}
                                                      <div className={cx('glo-table-col', 'text-left')}>{server.machine_name}</div>
                                                      {/* <div className={cx('glo-table-col')}>{server.os}</div> */}
                                                      {/* <div className={cx('glo-table-col')}>{server.distribution}</div> */}
                                                      {/* <div className={cx('glo-table-col', 'glo-table-col-version')}>
                                                          {server.version}
                                                      </div> */}
                                                      <div className={cx('glo-table-col', 'glo-table-col-stop', 'text-left')}>
                                                          {server.suspended === 1 ? 'オフ' : 'オン'}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.suspension_time &&
                                                              moment(server.suspension_time).format('YYYY-MM-DD')}{' '}
                                                          <br />
                                                          {server.suspension_time &&
                                                              moment(server.suspension_time).format('HH:mm:ss')}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.created_at &&
                                                              moment(server.created_at).format('YYYY-MM-DD')}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.updated_at &&
                                                              moment(server.updated_at).format('YYYY-MM-DD')}{' '}
                                                          <br />
                                                          {server.updated_at &&
                                                              moment(server.updated_at).format('HH:mm:ss')}
                                                      </div>
                                                  </div>
                                              ))
                                            : totalServers !== null && (
                                                  <p className={cx('no-match')}>結果を見つけない</p>
                                              )}
                                    </InfiniteScroll>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <FormModal
                isOpen={isOpenForm}
                setIsOpen={setIsOpenForm}
                data={dataForm}
                setData={setDataForm}
                callback={callback}
                setCallback={setCallback}
                setIdsChecked={setIdsChecked}
                setOffset={setOffset}
            />
        </>
    );
}

export default ServerList;
