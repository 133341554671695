import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import styles from './Monitoring.module.scss';
import { IoIosArrowDown, IoMdAddCircleOutline} from 'react-icons/io';
import { AiFillDelete, AiOutlineDownload } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { useMyContext } from '~/context/store';
import moment from 'moment';
import Swal from 'sweetalert2';
import FormModal from '~/components/FormModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDebounce from '~/hooks/useDebounce';
import { useAuthState } from '~/context';

const cx = classNames.bind(styles);

function Monitoring() {
    const [idsChecked, setIdsChecked] = useState([]);
    const [monitoring, setMonitoring] = useState([]);
    const { loading, setLoading, breadcrumbs, setBreadcrumbs } = useMyContext();

    const [callback, setCallback] = useState(false);
    const navigate = useNavigate();
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalMonitoring, setTotalMonitoring] = useState(null);
    const [search, setSearch] = useState();
    const searchDebounce = useDebounce(search, 600);
    const { breadcrumbsMonitoring, setBreadcrumbsMonitoring } = useMyContext();
    const userDetails = useAuthState();
    const [isShowMenuFilterGroupServer, setIsShowMenuFilterGroupServer] = useState(false);
    const [isShowMenuFilterServer, setIsShowMenuFilterServer] = useState(false);
    const [groupManages, setGroupManages] = useState([]);
    const [filterByGroupManages, setFilterByGroupManages] = useState({ id: null, name: null });
    const [filterByServer, setFilterByServer] = useState({ id: null, name: null });
    const [servers, setServers] = useState(null);

    const refDropdownGroupServer = useRef();
    const refDropdownServer = useRef();

    const handleRedirect = (id, name) => {
        if (userDetails.userDetails.role === 'Admin') {
            setBreadcrumbsMonitoring([
                { path: '/monitoring', breadcrumb: '監視先' },
                { path: `/monitoring/${id}`, breadcrumb: name },
            ]);
            navigate(`/monitoring/${id}`);
        } else {
            setBreadcrumbsMonitoring([
                { path: '/', breadcrumb: '監視先' },
                { path: `/monitoring/${id}`, breadcrumb: name },
            ]);
            navigate(`/monitoring/${id}`);
        }
    };

    useEffect(() => {
        const getMonitoring = async () => {
            const params = {
                offset,
                limit,
                // serverId: filterByServer.id,
                // groupServerId: filterByGroupManages.id,
            };
            const res = await api.get('api/sresults/filters', { params });
            if (res.status === 200) {
                setMonitoring([...monitoring, ...res.data.data.data]);
            }
        };
        offset >= 16 && getMonitoring();
    }, [offset]);

    useEffect(() => {
        const getMonitoring = async () => {
            setLoading(true);
            const params = {
                offset,
                limit,
                serverId: filterByServer.id,
                groupServerId: filterByGroupManages.id,
            };
            const res = await api.get('api/sresults/filters', { params });

            if (res.status === 200) {
                setMonitoring(res.data.data.data);
                setTotalMonitoring(res.data.meta.total);
                setLoading(false);
            }
        };

        // if (filterByGroupManages.id !== null || filterByServer.id !== null) {
        getMonitoring();
        // }
    }, [callback, filterByServer, filterByGroupManages]);

    const handleClickOutside = (event) => {
        if (refDropdownGroupServer.current && !refDropdownGroupServer.current.contains(event.target)) {
            setIsShowMenuFilterGroupServer(false);
        }
        if (refDropdownServer.current && !refDropdownServer.current.contains(event.target)) {
            setIsShowMenuFilterServer(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isShowMenuFilterGroupServer, isShowMenuFilterServer]);

    useEffect(() => {
        const getGroupManages = async () => {
            const res = await api.get('api/groupSurveillances');

            if (res.status === 200) {
                console.log(res.data.data)
                setGroupManages(res.data.data);
            }
    };

        getGroupManages();
    }, []);

    useEffect(() => {
        const getServers = async () => {
            const res = await api.get(`api/servers/${filterByGroupManages.id}/group-server`);

            if (res.status === 200) {
                setServers(res.data.data);
            }
        };

        filterByGroupManages.id !== null && getServers();
    }, [filterByGroupManages]);

    const handleSelectManage = (e, id, name) => {
        setFilterByGroupManages({ id, name });
        setFilterByServer({ id: null, name: null });
        setOffset(0);
    };

    const handleSelectServer = (id, name) => {
        setFilterByServer({ id, name });
        setOffset(0);
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleChangeSearch = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    const downloadCsv = (e) => {
        setLoading(true);
        const getResultExport = async () => {
            const params = {
                offset,
                limit,
                serverId: filterByServer.id,
                groupServerId: filterByGroupManages.id,
            };
            const res = await api.get(`api/sresults/export`, { params });

            if (res.status === 200) {
                setLoading(false);
                let url = window.URL.createObjectURL(new Blob(["\ufeff",res.data]));
                let a = document.createElement('a');
                a.href = url;
                a.download = 'server_result.csv';
                a.click();
            }
        };
        getResultExport();
    };

    const getStatus = (status) => {
        if (status === 0) {
            return <div className={cx('status-ok')}>OK</div>;
        } else if (status === 1) {
            return <div className={cx('status-stop')}>チェック中</div>;
        } else if (status === 2) {
            return <div className={cx('status-warning')}>警告</div>;
        } else if (status === 3) {
            return <div className={cx('status-error')}>エラー</div>;
        }
    };

    return (
        <>
            <div className={cx('Breadcrumbs_action')}>
                <>
                    <div className={cx('wrap-btn-action')}>
                        <button
                            className={cx('btn-action', 'btn-action-edit')}
                            onClick={downloadCsv}
                        >
                            <AiOutlineDownload className={cx('btn-action__icon-edit')} />
                            <span className={cx('btn-action__name', 'btn-action__name-edit')}>ダウンロード</span>
                        </button>
                    </div>
                    <div className={cx('dropdown', 'wrap-dropdown')} ref={refDropdownGroupServer}>
                        <button
                            type="button"
                            id="menuFilterGroupServer"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className={cx('btn-select')}
                            onClick={() => setIsShowMenuFilterGroupServer(!isShowMenuFilterGroupServer)}
                        >
                            <div className={cx('btn-select__name')}>
                                {filterByGroupManages.id ? (
                                    <span className={cx('btn-select__name-active')}>{filterByGroupManages.name}</span>
                                ) : (
                                    '監視グループを選択する'
                                )}
                            </div>
                            <div className={cx('btn-filter__icon')}>
                                <IoIosArrowDown />
                            </div>
                        </button>

                        {isShowMenuFilterGroupServer && (
                            <ul
                                className={cx('dropdown-menu')}
                                id={cx('dropdown-menu')}
                                aria-labelledby="menuFilterGroupServer"
                                onClick={() => setIsShowMenuFilterGroupServer(true)}
                            >
                                <li
                                    className={cx('row-checkbox')}
                                    onClick={() => {
                                        setFilterByGroupManages({ id: null, name: null });
                                        setFilterByServer({ id: null, name: null });
                                        // setCallback(!callback);
                                    }}
                                >
                                    <input
                                        id="input-chkbox-none"
                                        type="radio"
                                        checked={filterByGroupManages.id === null}
                                        className={cx('row-checkbox-input')}
                                    />
                                    <label htmlFor={`input-chkbox-none`} className={cx('row-checkbox-name')}>
                                        なし
                                    </label>
                                </li>
                                {groupManages.map((group) => (
                                    <li
                                        className={cx('row-checkbox')}
                                        key={group.id}
                                        onClick={(e) => handleSelectManage(e, group.id, group.group_name)}
                                    >
                                        <input
                                            id={`input-chkbox-${group.id}`}
                                            type="radio"
                                            checked={filterByGroupManages.id === group.id}
                                            className={cx('row-checkbox-input')}
                                        />
                                        <label
                                            htmlFor={`input-chkbox-${group.id}`}
                                            // onClick={(e) => handleSelectManage(e, group.id, group.group_name)}
                                            className={cx('row-checkbox-name')}
                                        >
                                            {group.group_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className={cx('dropdown', 'wrap-dropdown')} ref={refDropdownServer}>
                        <button
                            type="button"
                            id="menuFilterServer"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className={cx('btn-select')}
                            disabled={!filterByGroupManages.id}
                            onClick={() => setIsShowMenuFilterServer(!isShowMenuFilterServer)}
                        >
                            <div className={cx('btn-select__name')}>
                                {filterByServer.id ? (
                                    <span className={cx('btn-select__name-active')}>{filterByServer.name}</span>
                                ) : (
                                    'サーバーを選択する'
                                )}
                            </div>
                            <div className={cx('btn-filter__icon')}>
                                <IoIosArrowDown />
                            </div>
                        </button>

                        {isShowMenuFilterServer && (
                            <ul
                                className={cx('dropdown-menu')}
                                id={cx('dropdown-menu')}
                                aria-labelledby="menuFilterServer"
                                onClick={() => setIsShowMenuFilterServer(true)}
                            >
                                {/* <li
                                    className={cx('row-checkbox')}
                                    onClick={() => setFilterByServer({ id: null, name: null })}
                                >
                                    <input
                                        id="input-chkbox-none-server"
                                        type="radio"
                                        checked={filterByServer.id === null}
                                        className={cx('row-checkbox-input')}
                                    />
                                    <label htmlFor={`input-chkbox-none-server`} className={cx('row-checkbox-name')}>
                                        なし
                                    </label>
                                </li> */}
                                {servers?.map((server) => (
                                    <li
                                        className={cx('row-checkbox')}
                                        key={server.id}
                                        onClick={() => handleSelectServer(server.id, server.server_name)}
                                    >
                                        <input
                                            id={`input-radio-${server.id}`}
                                            type="radio"
                                            checked={filterByServer.id === server.id}
                                            className={cx('row-checkbox-input')}
                                        />
                                        <label
                                            htmlFor={`input-radio-${server.id}`}
                                            onClick={() => handleSelectServer(server.id)}
                                            className={cx('row-checkbox-name')}
                                        >
                                            {server.server_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            </div>
            <div className={cx('scroll-outer-wrapper')}>
                <div className={cx('scroll-outer')}>
                    <div className={cx('wrap-table')} style={{ overflowX: loading ? 'hidden' : 'auto', height : 'calc(82vh - 40px)' }}>
                        <div className={cx('list-table')}>
                            <div className={cx('list-title-table')}>
                                {/* <div className={cx('glo-table-col-checkbox')}></div> */}
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>ID</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>サーバー名</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>モニター名</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>IPアドレス</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-stop', 'text-left')}>ポートID</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>URL</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ステータス</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>実施時間</div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>最新結果</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>最近エラー</div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>作成日</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>更新日</div> */}
                            </div>

                            <div className={cx('infinite-scroll-container')}>
                                {!loading && (
                                    <InfiniteScroll
                                        dataLength={monitoring.length}
                                        next={fetchData}
                                        hasMore={monitoring.length >= totalMonitoring ? false : true}
                                        loader={<h4>読み込み中...</h4>}
                                    >
                                        {monitoring.length > 0
                                            ? monitoring.map((monitor, index) => (
                                                  <div
                                                      className={cx('list-item-body-tr')}
                                                      key={monitor.id}
                                                      onClick={() =>
                                                          handleRedirect(monitor.id, monitor.monitor.view_name)
                                                      }
                                                  >
                                                      {/* <div className={cx('glo-table-col')}>{monitor.id}</div> */}
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {monitor.monitor.data.server.server_name}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {monitor.monitor.data.view_name}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {monitor.monitor.data.server.ip_address}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'glo-table-col-stop', 'text-right')}>
                                                          {monitor.monitor.data.port_id}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {monitor.monitor.data.url}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'glo-table-col__status')}>
                                                          {getStatus(monitor.state)}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {monitor.execution_time}
                                                      </div>
                                                      {/* <div className={cx('glo-table-col')}>
                                                          {monitor.latest_results === 0 ? 'OK' : 'NG'}
                                                      </div> */}
                                                      <div className={cx('glo-table-col', 'text-left')}>{monitor.recent_error}</div>
                                                      {/* <div className={cx('glo-table-col')}>
                                                          {monitor.created_at &&
                                                              moment(monitor.created_at).format('YYYY-MM-DD')}
                                                      </div>
                                                      <div className={cx('glo-table-col')}>{monitor.updated_at}</div> */}
                                                  </div>
                                              ))
                                            : totalMonitoring !== null && (
                                                  <p className={cx('no-match')}>結果を見つけない</p>
                                              )}
                                    </InfiniteScroll>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <FormModal
                isOpen={isOpenForm}
                setIsOpen={setIsOpenForm}
                data={dataForm}
                setData={setDataForm}
                callback={callback}
                setCallback={setCallback}
            /> */}
        </>
    );
}

export default Monitoring;
