import routesConfig from '~/config/routes';
import GroupManages from '~/pages/GroupManages';
import Home from '~/pages/Home';
import Monitored from '~/pages/Home/components/Monitored';
import Server from '~/pages/Home/components/Servers';
import Login from '~/pages/Login';
import Monitoring from '~/pages/Monitoring';
import MonitoringResult from '~/pages/MonitoringResult';
import NotFound from '~/pages/NotFound';
import Users from '~/pages/Users';
import ServerList from '~/pages/ServerList';
import UserList from '~/pages/UserList';
import ServerUserList from '~/pages/ServerUserList';

const publicRoutes = [{ path: routesConfig.login, component: Login, layout: null }];

const privateRoutes = [
    { path: routesConfig.home, component: Home },
    { path: routesConfig.server, component: Server },
    { path: routesConfig.serverList, component: ServerList },
    { path: routesConfig.userList, component: UserList },
    { path: routesConfig.monitored, component: Monitored },
    { path: routesConfig.users, component: Users },
    { path: routesConfig.groupManages, component: GroupManages },
    { path: routesConfig.monitoring, component: Monitoring },
    { path: routesConfig.monitoringLResult, component: MonitoringResult },
    { path: routesConfig.notFound, component: NotFound, layout: null },
];

const privateRoutesUser = [
    { path: routesConfig.home, component: Monitoring },
    { path: routesConfig.monitoringLResult, component: MonitoringResult },
    { path: routesConfig.serverUserList, component: ServerUserList },
    { path: routesConfig.notFound, component: NotFound, layout: null },
];

export { publicRoutes, privateRoutes, privateRoutesUser };
