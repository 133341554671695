import axios from 'axios';

const instance = axios.create({
    baseURL: window.location.hostname.startsWith('local') ? process.env.REACT_APP_BASE_URL : `${window.location.origin}/`,
    headers: {
        'Content-Type': 'application/json',
    },
});

function getLocalAccessToken() {
    const accessToken = window.localStorage.getItem('accessToken');
    return accessToken;
}

instance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (config.url !== "/login" && token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    window.location.href = '/login';
  }
  throw error;
});

export default instance;
