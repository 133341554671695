import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import styles from './Monitored.module.scss';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { BsSignStop } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import routesConfig from '~/config/routes';
import { useMyContext } from '~/context/store';
import api from '~/utils/main';
import moment from 'moment';
import Swal from 'sweetalert2';
import FormModal from '~/components/FormModal';
import Breadcrumbs from '~/components/Breadcrumbs';
import useDebounce from '~/hooks/useDebounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiPlayCircle } from 'react-icons/fi';

const cx = classNames.bind(styles);

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

function Monitored() {
    const [idsChecked, setIdsChecked] = useState([]);
    const [monitors, setMonitors] = useState([]);
    const [callback, setCallback] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const navigate = useNavigate();
    const { loading, setLoading } = useMyContext();
    const { id } = useParams();
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalMonitors, setTotalMonitors] = useState(null);
    const [search, setSearch] = useState('');
    const searchDebounce = useDebounce(search, 600);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleAddId = (e) => {
        setIdsChecked([...idsChecked, e]);

        if (idsChecked.includes(e)) {
            setIdsChecked(idsChecked.filter((y) => y !== e));
        }
    };

    useEffect(() => {
        const getMonitores = async () => {
            setLoading(true);

            const params = {
                offset,
                limit,
                searchTerm: search,
                serverId: [id],
            };
            const res = await api.get(`api/monitores/filters`, { params });

            if (res.status === 200) {
                setMonitors(res.data.data.data);
                setTotalMonitors(res.data.meta.total);
                setLoading(false);
            }
        };

        getMonitores();
    }, [callback, searchDebounce]);

    useEffect(() => {
        const getMonitores = async () => {
            const params = {
                offset,
                limit,
                serverId: [id],
            };
            const res = await api.get('api/monitores/filters', { params });
            if (res.status === 200) {
                setMonitors([...monitors, ...res.data.data.data]);
            }
        };
        offset >= 16 && getMonitores();
    }, [offset]);

    const handleRedirectGroup = () => {
        navigate(routesConfig.home);
    };

    const handleRedirectServer = () => {
        navigate(routesConfig.server);
    };

    const handleAddMonitor = () => {
        setIsOpenForm(true);
        setDataForm({
            type: 'add',
            titleForm: 'Add Monitor',
            text: 'モニター追加',
            data: [
                { value: '', title: 'URL', name: 'url', isShow: true },
                // { value: '', title: 'IPアドレス', name: 'ip_address', isShow: true },
                { value: '', title: 'ポートID', name: 'port_id', isShow: true },
                { value: '', title: 'ビュー名', name: 'view_name', isShow: true },
                {
                    value: '',
                    title: 'ストップ',
                    name: 'stopped',
                    isShow: true,
                    min: 0,
                    max: 1,
                    placeholder: '0:オフ 1:オン',
                },
                { value: id, title: 'Server', name: 'server_id', type: 'select', isShow: false },
            ],
        });
    };

    const handleEdit = () => {
        const groupServerActive = monitors.find((ele) => ele.id === idsChecked[0]);
        setIsOpenForm(true);

        setDataForm({
            type: 'edit',
            titleForm: 'Edit Monitor',
            text: 'モニター編集',
            id: idsChecked[0],
            data: [
                { value: groupServerActive.url, title: 'URL', name: 'url', isShow: true },
                // { value: groupServerActive.ip_address, title: 'IPアドレス', name: 'ip_address', isShow: true },
                { value: groupServerActive.port_id, title: 'ポートID', name: 'port_id', isShow: true },
                { value: groupServerActive.view_name, title: 'ビュー名', name: 'view_name', isShow: true },
                { value: groupServerActive.stopped, title: 'ストップ', name: 'stopped', isShow: true, min: 0, max: 1 },
                {
                    value: groupServerActive.server.id,
                    title: 'Server',
                    name: 'server_id',
                    type: 'select',
                    isShow: false,
                },
            ],
        });
    };

    const handleDeleteMonitors = () => {
        Swal.fire({
            title: '確認',
            text: `モニターを削除しますか？`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            cancelButtonText: 'キャンセル',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await Promise.all(
                    idsChecked.map(async (id) => {
                        await api.delete(`api/monitores/${id}`);
                    }),
                );
                setCallback(!callback);
                setIdsChecked([]);
                setOffset(0);
                Swal.fire('通知', `モニターを削除しました`, 'success');
            }
        });
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleSearch = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    const handleChangeStopped = async (e, id) => {
        e.stopPropagation();
        const res = await api.patch(`api/monitores/${id}/status`);
        if (res.status === 200) {
            setCallback(!callback);
        }
    };

    const getStatus = (status) => {
        if (status === 0) {
            return <div className={cx('status-ok')}>OK</div>;
        } else if (status === 1) {
            return <div className={cx('status-stop')}>チェック中</div>;
        } else if (status === 2) {
            return <div className={cx('status-warning')}>警告</div>;
        } else if (status === 3) {
            return <div className={cx('status-error')}>エラー</div>;
        }
    };

    return (
        <>
            <div className={cx('Breadcrumbs_action')}>
                {/* <div className={cx('Breadcrumbs')}>
                    <span className={cx('Breadcrumbs_name')} onClick={handleRedirectGroup} >Group server 1</span>
                    <span className={cx('icon-next')}>{'>'}</span>
                    <span className={cx('Breadcrumbs_name')} onClick={handleRedirectServer}>Server 3</span>
                    <span className={cx('icon-next')}>{'>'}</span>
                    <span className={cx('Breadcrumbs_name-disable')}>http://tool.glotechvn.com/task/21075</span>
                </div> */}

                <Breadcrumbs pathCurrent={`/server/${id}`} />

                {/* button action */}
                <div className={cx('wrap-btn-action')}>
                    <input className={cx('input-search')} placeholder="監視検索" onChange={handleSearch} />
                    <button className={cx('btn-action', 'btn-action-add')} onClick={handleAddMonitor}>
                        <IoMdAddCircleOutline className={cx('btn-action__icon-add')} />
                        <span className={cx('btn-action__name', 'btn-action__name-add')}>モニター追加</span>
                    </button>

                    <button
                        className={cx('btn-action', 'btn-action-edit')}
                        disabled={idsChecked.length !== 1}
                        onClick={handleEdit}
                    >
                        <MdModeEditOutline className={cx('btn-action__icon-edit')} />
                        <span className={cx('btn-action__name', 'btn-action__name-edit')}>編集</span>
                    </button>
                    <button
                        className={cx('btn-action', 'btn-action-delete')}
                        disabled={idsChecked.length < 1}
                        onClick={handleDeleteMonitors}
                    >
                        <AiFillDelete className={cx('btn-action__icon-delete')} />
                        <span className={cx('btn-action__name', 'btn-action__name-delete')}>削除</span>
                    </button>
                </div>
            </div>
            <div className={cx('scroll-outer-wrapper')}>
                <div className={cx('scroll-outer')}>
                    <div className={cx('wrap-table')} style={{ overflowX: loading ? 'hidden' : 'auto', height : 'calc(82vh - 40px)' }}>
                        <div className={cx('list-table')}>
                            <div className={cx('list-title-table')}>
                                <div className={cx('glo-table-col-checkbox')}></div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-id')}>ID</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-serverId', 'text-left')}>
                                    サーバー名
                                </div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ステータス</div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>IPアドレス</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-stop', 'text-left')}>ポートID</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-rows-2', 'text-left')}>
                                    URL
                                </div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ビュー名</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ストップ</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>作成日</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>スキャン時刻</div>
                            </div>

                            {!loading && (
                                <div className={cx('infinite-scroll-container')}>
                                    <InfiniteScroll
                                        dataLength={monitors.length}
                                        next={fetchData}
                                        hasMore={monitors.length >= totalMonitors ? false : true}
                                        loader={<h4>読み込み中...</h4>}
                                    >
                                        {monitors.length > 0
                                            ? monitors.map((monitor, index) => (
                                                  <div className={cx('list-item-body-tr')} key={monitor.id}>
                                                      <div
                                                          className={cx('glo-table-col-checkbox')}
                                                          onClick={() => handleAddId(monitor.id)}
                                                      >
                                                          <input
                                                              type="checkbox"
                                                              checked={idsChecked.includes(monitor.id)}
                                                          />
                                                      </div>
                                                      {/* <div className={cx('glo-table-col', 'glo-table-col-id')}>
                                                          {monitor.id}
                                                      </div> */}
                                                      <div className={cx('glo-table-col', 'glo-table-col-serverId', 'text-left')}>
                                                          {monitor.server.server_name}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'glo-table-col__status', 'text-left')}>
                                                          {getStatus(monitor.state)}
                                                      </div>
                                                      {/* <div className={cx('glo-table-col')}>{monitor.ip_address}</div> */}
                                                      <div className={cx('glo-table-col', 'glo-table-col-stop', 'text-right')}>{monitor.port_id}</div>
                                                      <div className={cx('glo-table-col', 'glo-table-col-rows-2', 'text-left')}>
                                                          {monitor.url}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>{monitor.view_name}</div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          <span>{monitor.stopped === 0 ? '実行中' : 'ストップ中'}</span>
                                                          <span
                                                              className={cx(
                                                                  `glo-table-col-icon-${
                                                                      monitor.stopped === 0 ? 'stop' : 'run'
                                                                  }`,
                                                              )}
                                                              onClick={(e) => handleChangeStopped(e, monitor.id)}
                                                          >
                                                              {monitor.stopped === 0 ? (
                                                                  <BsSignStop />
                                                              ) : (
                                                                  <FiPlayCircle />
                                                              )}
                                                          </span>
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {monitor.created_at &&
                                                              moment(monitor.created_at).format('YYYY-MM-DD')}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {monitor.updated_at &&
                                                              moment(monitor.updated_at).format('YYYY-MM-DD')}{' '}
                                                          <br />
                                                          {monitor.updated_at &&
                                                              moment(monitor.updated_at).format('HH:mm:ss')}
                                                      </div>
                                                  </div>
                                              ))
                                            : totalMonitors !== null && (
                                                  <p className={cx('no-match')}>結果を見つけない</p>
                                              )}
                                    </InfiniteScroll>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <FormModal
                isOpen={isOpenForm}
                setIsOpen={setIsOpenForm}
                data={dataForm}
                setData={setDataForm}
                callback={callback}
                setCallback={setCallback}
                setIdsChecked={setIdsChecked}
                setOffset={setOffset}
            />
        </>
    );
}

export default Monitored;
