import './App.css';
import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes, privateRoutes, privateRoutesUser } from '~/routes';
import DefaultLayout from './layouts';
import Loading from './components/Loading';
import { useMyContext } from './context/store';
import { useAuthState } from './context';
import routesConfig from '~/config/routes';

function RequireAuth({ children, redirectTo }) {
    const userDetails = useAuthState();
    const { isAuthenticated } = userDetails;
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function RequireLoginAuth({ children, redirectTo }) {
    const userDetails = useAuthState();
    const { isAuthenticated } = userDetails;
    return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}

function App() {
    const { loading, setLoading } = useMyContext();
    const userDetails = useAuthState();

    return (
        <Router>
            {loading && <Loading />}
            <div className="App">
                <Routes>
                    {publicRoutes.map((route, index) => {
                        let Layout = DefaultLayout;
                        let Page = route.component;

                        if (route.layout === null) {
                            Layout = Fragment;
                        }

                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <RequireLoginAuth redirectTo={routesConfig.home}>
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    </RequireLoginAuth>
                                }
                            />
                        );
                    })}
                    {(userDetails.userDetails.role === 'Admin' ? privateRoutes : privateRoutesUser).map(
                        (route, index) => {
                            let Layout = DefaultLayout;
                            let Page = route.component;

                            if (route.layout === null) {
                                Layout = Fragment;
                            }
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <RequireAuth redirectTo={routesConfig.login}>
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        </RequireAuth>
                                    }
                                />
                            );
                        },
                    )}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
