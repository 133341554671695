const routes = {
    home: '/',
    login: '/login',
    server: '/group/:id',
    monitored: '/server/:id',
    users: '/groupManages/:id',
    groupManages: '/groupManages',
    monitoring: '/monitoring',
    monitoringLResult: '/monitoring/:id',
    notFound: '/*',
    serverList: '/serverList',
    userList: '/userList',
    serverUserList: '/serverUserList',
};

export default routes;
