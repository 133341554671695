import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './GroupManages.module.scss';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { useMyContext } from '~/context/store';
import moment from 'moment';
import Swal from 'sweetalert2';
import FormModal from '~/components/FormModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDebounce from '~/hooks/useDebounce';

const cx = classNames.bind(styles);

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

function GroupManages() {
    const [idsChecked, setIdsChecked] = useState([]);
    const [groupManages, setgroupManages] = useState([]);
    const { loading, setLoading, breadcrumbs, setBreadcrumbs } = useMyContext();

    const [callback, setCallback] = useState(false);
    const navigate = useNavigate();
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalgroupManages, setTotalgroupManages] = useState(null);
    const [search, setSearch] = useState();
    const searchDebounce = useDebounce(search, 600);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleAddId = (e, id) => {
        e.stopPropagation();
        setIdsChecked([...idsChecked, id]);

        if (idsChecked.includes(id)) {
            setIdsChecked(idsChecked.filter((y) => y !== id));
        }
    };

    const handleRedirect = (id, name) => {
        setBreadcrumbs([
            { path: '/groupManages', breadcrumb: 'グループユーザー管理' },
            { path: `/groupManages/${id}`, breadcrumb: name },
        ]);
        navigate(`/groupManages/${id}`);
    };

    useEffect(() => {
        const getgroupManages = async () => {
            const params = {
                offset,
                limit,
            };
            const res = await api.get('api/groupManages/filters', { params });
            if (res.status === 200) {
                setgroupManages([...groupManages, ...res.data.data.data]);
            }
        };
        offset >= 16 && getgroupManages();
    }, [offset]);

    useEffect(() => {
        const getGroupServer = async () => {
            setLoading(true);
            const params = {
                offset,
                limit,
                searchTerm: search,
            };
            const res = await api.get('api/groupManages/filters', { params });

            if (res.status === 200) {
                setgroupManages(res.data.data.data);
                setTotalgroupManages(res.data.meta.total);
                setLoading(false);
            }
        };

        getGroupServer();
    }, [callback, searchDebounce]);

    const handleDeleteGroup = () => {
        Swal.fire({
            title: '確認',
            text: `グループユーザーを削除しますか？`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            cancelButtonText: 'キャンセル',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await Promise.all(
                    idsChecked.map(async (id) => {
                        await api.delete(`api/groupManages/${id}`);
                    }),
                );
                setCallback(!callback);
                setIdsChecked([]);
                setOffset(0);
                Swal.fire('通知', `グループユーザーを削除しました`, 'success');
            }
        });
    };

    const handleAddGroupServer = () => {
        setIsOpenForm(true);
        setDataForm({
            type: 'add',
            titleForm: 'Add Groups Manage',
            text: '新規グループユーザー追加',
            data: [{ value: '', title: 'グループ名', name: 'group_name', isShow: true }],
        });
    };

    const handleEditGroupServer = () => {
        const groupServerActive = groupManages.find((ele) => ele.id === idsChecked[0]);
        setIsOpenForm(true);

        setDataForm({
            type: 'edit',
            titleForm: 'Edit Groups Manage',
            text: 'グループユーザー管理編集',
            id: idsChecked[0],
            data: [{ value: groupServerActive.group_name, title: 'グループ名', name: 'group_name', isShow: true }],
        });
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleChangeSearch = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    return (
        <>
            <div className={cx('Breadcrumbs_action')}>
                <div className={cx('Breadcrumbs')}></div>

                {/* button action */}
                <div className={cx('wrap-btn-action')}>
                    <input
                        className={cx('input-search')}
                        placeholder="グループユーザー検索"
                        onChange={handleChangeSearch}
                    />
                    <button className={cx('btn-action', 'btn-action-add')} onClick={handleAddGroupServer}>
                        <IoMdAddCircleOutline className={cx('btn-action__icon-add')} />
                        <span className={cx('btn-action__name', 'btn-action__name-add')}>新規グループユーザー追加</span>
                    </button>

                    <button
                        className={cx('btn-action', 'btn-action-edit')}
                        onClick={handleEditGroupServer}
                        disabled={idsChecked.length !== 1}
                    >
                        <MdModeEditOutline className={cx('btn-action__icon-edit')} />
                        <span className={cx('btn-action__name', 'btn-action__name-edit')}>編集</span>
                    </button>
                    <button
                        className={cx('btn-action', 'btn-action-delete')}
                        disabled={idsChecked.length < 1}
                        onClick={() => handleDeleteGroup()}
                    >
                        <AiFillDelete className={cx('btn-action__icon-delete')} />
                        <span className={cx('btn-action__name', 'btn-action__name-delete')}>削除</span>
                    </button>
                </div>
            </div>
            <div className={cx('wrap-table')}>
                <div className={cx('list-table')}>
                    <div className={cx('list-title-table')}>
                        <div className={cx('glo-table-col-checkbox')}></div>
                        {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>ID</div> */}
                        <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>グループ名</div>
                        {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>Delay</div>
                        <div className={cx('glo-table-col', 'glo-table-col-name')}>State</div> */}
                        <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>作成日</div>
                        <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>更新日</div>
                    </div>

                    <div className={cx('scroll-table')}>
                        {!loading && (
                            <InfiniteScroll
                                dataLength={groupManages.length}
                                next={fetchData}
                                hasMore={groupManages.length >= totalgroupManages ? false : true}
                                loader={<h4>読み込み中...</h4>}
                                height={windowSize.innerHeight - 275}
                            >
                                {groupManages.length > 0
                                    ? groupManages.map((group, index) => (
                                          <div
                                              className={cx('list-item-body-tr')}
                                              key={group.id}
                                              onClick={() => handleRedirect(group.id, group.group_name)}
                                          >
                                              <div
                                                  className={cx('glo-table-col-checkbox')}
                                                  onClick={(e) => handleAddId(e, group.id)}
                                              >
                                                  <input type="checkbox" checked={idsChecked.includes(group.id)} />
                                              </div>
                                              {/* <div className={cx('glo-table-col')}>{group.id}</div> */}
                                              <div className={cx('glo-table-col', 'glo-table-col__groupname', 'text-left')}>
                                                  <span>{group.group_name}</span>
                                              </div>
                                              {/* <div className={cx('glo-table-col')}>{group.delay}</div>
                                              <div className={cx('glo-table-col', 'glo-table-col__status')}>    
                                                  <div className={cx('status-ok')}>{group.state}</div>
                                              </div> */}
                                              <div className={cx('glo-table-col', 'text-left')}>
                                                  {group.created_at && moment(group.created_at).format('YYYY-MM-DD')}
                                              </div>
                                              <div className={cx('glo-table-col', 'text-left')}>{group.updated_at}</div>
                                          </div>
                                      ))
                                    : totalgroupManages !== null && <p className={cx('no-match')}>結果を見つけない</p>}
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            </div>

            <FormModal
                isOpen={isOpenForm}
                setIsOpen={setIsOpenForm}
                data={dataForm}
                setData={setDataForm}
                callback={callback}
                setCallback={setCallback}
                setIdsChecked={setIdsChecked}
                setOffset={setOffset}
            />
        </>
    );
}

export default GroupManages;
