import React, { createContext, useContext, useState } from 'react';

export const Store = createContext();

export const ContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [breadcrumbsUser, setBreadcrumbsUser] = useState([]);
    const [breadcrumbsMonitoring, setBreadcrumbsMonitoring] = useState([]);

    const value = {
        loading,
        setLoading,
        breadcrumbs,
        setBreadcrumbs,
        breadcrumbsUser,
        setBreadcrumbsUser,
        breadcrumbsMonitoring,
        setBreadcrumbsMonitoring,
    };

    return <Store.Provider value={value}>{children}</Store.Provider>;
};

export const useMyContext = () => useContext(Store);
