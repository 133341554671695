import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './MonitoringResult.module.scss';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { useMyContext } from '~/context/store';
import moment from 'moment';
import Swal from 'sweetalert2';
import FormModal from '~/components/FormModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDebounce from '~/hooks/useDebounce';
import BreadcrumbsMonitoring from '~/components/BreadcrumbsMonitoring';

const cx = classNames.bind(styles);

function MonitoringResult() {
    const [idsChecked, setIdsChecked] = useState([]);
    const [monitoring, setMonitoring] = useState([]);
    const { loading, setLoading, breadcrumbs, setBreadcrumbs } = useMyContext();

    const [callback, setCallback] = useState(false);
    const navigate = useNavigate();
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalMonitoring, setTotalMonitoring] = useState(null);
    const [search, setSearch] = useState();
    const searchDebounce = useDebounce(search, 600);
    const { id } = useParams();

    // const handleAddId = (e) => {
    //     setIdsChecked([...idsChecked, e]);

    //     if (idsChecked.includes(e)) {
    //         setIdsChecked(idsChecked.filter((y) => y !== e));
    //     }
    // };

    // const handleRedirect = (id, name) => {
    //     setBreadcrumbs([
    //         { path: '/monitoring', breadcrumb: 'Monitoring' },
    //         { path: `/monitoring/${id}`, breadcrumb: name },
    //     ]);
    //     navigate(`/monitoring/${id}`);
    // };

    useEffect(() => {
        const getMonitoringResult = async () => {
            const params = {
                offset,
                limit,
            };
            const res = await api.get(`api/lresults/${id}/sresult`, { params });
            if (res.status === 200) {
                setMonitoring([...monitoring, ...res.data.data.data]);
            }
        };
        offset >= 16 && getMonitoringResult();
    }, [offset]);

    useEffect(() => {
        const getMonitoring = async () => {
            setLoading(true);
            const params = {
                offset,
                limit,
                searchTerm: search,
            };
            const res = await api.get(`api/lresults/${id}/sresult`, { params });

            if (res.status === 200) {
                setMonitoring(res.data.data.data);
                setTotalMonitoring(res.data.meta.total);
                setLoading(false);
            }
        };

        getMonitoring();
    }, [callback, searchDebounce]);

    // const handleDeleteGroup = () => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: `You want to delete the groups?`,
    //         icon: 'warning',
    //         width: '360px',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'OK',
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             await Promise.all(
    //                 idsChecked.map(async (id) => {
    //                     await api.delete(`api/groupManages/${id}`);
    //                 }),
    //             );
    //             setCallback(!callback);
    //             setIdsChecked([]);
    //             setOffset(0);
    //             Swal.fire('Success!', `Delete groups successfully`, 'success');
    //         }
    //     });
    // };

    // const handleAddGroupServer = () => {
    //     setIsOpenForm(true);
    //     setDataForm({
    //         type: 'add',
    //         titleForm: 'Add Groups Manage',
    //         data: [
    //             { value: '', title: 'Group Name', name: 'group_name' },
    //             { value: '', title: 'Delay', name: 'delay' },
    //             { value: '', title: 'State', name: 'state' },
    //         ],
    //     });
    // };

    // const handleEditGroupServer = () => {
    //     const groupServerActive = groupManages.find((ele) => ele.id === idsChecked[0]);
    //     setIsOpenForm(true);

    //     setDataForm({
    //         type: 'edit',
    //         titleForm: 'Edit Groups Manage',
    //         id: idsChecked[0],
    //         data: [
    //             { value: groupServerActive.group_name, title: 'Group Name', name: 'group_name' },
    //             { value: groupServerActive.delay, title: 'Delay', name: 'delay' },
    //             { value: groupServerActive.state, title: 'State', name: 'state' },
    //         ],
    //     });
    // };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleChangeSearch = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    const getStatus = (status) => {
        if (status === 0) {
            return <div className={cx('status-ok')}>OK</div>;
        } else if (status === 1) {
            return <div className={cx('status-stop')}>グリーン</div>;
        } else if (status === 2) {
            return <div className={cx('status-warning')}>イエロー</div>;
        } else if (status === 3) {
            return <div className={cx('status-error')}>レッド</div>;
        }
    };

    return (
        <>
            <div className={cx('Breadcrumbs_action')}>
                <div className={cx('Breadcrumbs')}>
                    <BreadcrumbsMonitoring pathCurrent={`/monitoring/${id}`} />
                </div>

                {/* button action */}
                {/* <div className={cx('wrap-btn-action')}>
                    <input
                        className={cx('input-search')}
                        placeholder="Search Group Server"
                        onChange={handleChangeSearch}
                    />
                    <button className={cx('btn-action', 'btn-action-add')} onClick={handleAddGroupServer}>
                        <IoMdAddCircleOutline className={cx('btn-action__icon-add')} />
                        <span className={cx('btn-action__name', 'btn-action__name-add')}>Add new group server</span>
                    </button>

                    <button
                        className={cx('btn-action', 'btn-action-edit')}
                        onClick={handleEditGroupServer}
                        disabled={idsChecked.length !== 1}
                    >
                        <MdModeEditOutline className={cx('btn-action__icon-edit')} />
                        <span className={cx('btn-action__name', 'btn-action__name-edit')}>Edit</span>
                    </button>
                    <button
                        className={cx('btn-action', 'btn-action-delete')}
                        disabled={idsChecked.length < 1}
                        onClick={() => handleDeleteGroup()}
                    >
                        <AiFillDelete className={cx('btn-action__icon-delete')} />
                        <span className={cx('btn-action__name', 'btn-action__name-delete')}>Delete</span>
                    </button>
                </div> */}
            </div>
            <div className={cx('wrap-table')}>
                <div className={cx('list-table')}>
                    <div className={cx('list-title-table')}>
                        {/* <div className={cx('glo-table-col-checkbox')}></div> */}
                        {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>ID</div> */}
                        <div className={cx('glo-table-col', 'glo-table-col-name')}>実施時刻</div>
                        <div className={cx('glo-table-col', 'glo-table-col-name')}>最新結果</div>
                    </div>

                    <div className={cx('scroll-table')}>
                        {!loading && (
                            <InfiniteScroll
                                dataLength={monitoring.length}
                                next={fetchData}
                                hasMore={monitoring.length >= totalMonitoring ? false : true}
                                loader={<h4>読み込み中...</h4>}
                                height={700}
                            >
                                {monitoring.length > 0
                                    ? monitoring.map((monitor, index) => (
                                          <div className={cx('list-item-body-tr')} key={monitor.id}>
                                              {/* <div className={cx('glo-table-col')}>{monitor.id}</div> */}
                                              <div className={cx('glo-table-col')}>{monitor.execution_time}</div>
                                              <div className={cx('glo-table-col')}>{monitor.latest_results}</div>
                                          </div>
                                      ))
                                    : totalMonitoring !== null && <p className={cx('no-match')}>結果を見つけない</p>}
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MonitoringResult;
