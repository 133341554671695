import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './ServerUserList.module.scss';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { useMyContext } from '~/context/store';
import moment from 'moment';
import FormModal from '~/components/FormModal';
import Swal from 'sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import Breadcrumbs from '~/components/Breadcrumbs';
import useDebounce from '~/hooks/useDebounce';

const cx = classNames.bind(styles);

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

function ServerUserList() {
    const [servers, setServers] = useState([]);
    const { loading, setLoading } = useMyContext();
    const [callback, setCallback] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalServers, setTotalServers] = useState(null);
    const [search, setSearch] = useState('');

    const searchDebounce = useDebounce(search, 600);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const getAllServers = async () => {
            setLoading(true);
            const params = {
                offset,
                limit,
                searchTerm: search,
            };
            const res = await api.get(`api/servers/filters`, { params });
            console.log(res)
            if (res.status === 200) {
                setServers(res.data.data.data);
                setTotalServers(res.data.meta.total);
                setLoading(false);
            }
        };

        getAllServers();
    }, [callback, searchDebounce]);

    // useEffect(() => {
    //     const getGroupServers = async () => {
    //         const params = {
    //             offset,
    //             limit,
    //             groupSurveillanceIds: [id],
    //         };
    //         const res = await api.get('api/servers/server-user-list', { params });
    //         if (res.status === 200) {
    //             setServers([...servers, ...res.data.data.data]);
    //         }
    //     };
    //     offset >= 16 && getGroupServers();
    // }, [offset]);

    const handleRedirect = () => {
        navigate(routesConfig.home);
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleSearch = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    const getStatus = (status) => {
        if (status === 0) {
            return <div className={cx('status-ok')}>OK</div>;
        } else if (status === 1) {
            return <div className={cx('status-stop')}>チェック中</div>;
        } else if (status === 2) {
            return <div className={cx('status-warning')}>警告</div>;
        } else if (status === 3) {
            return <div className={cx('status-error')}>エラー</div>;
        }
    };

    return (
        <>
            <div className={cx('wrap-header')}>
                <div className={cx('wrap-header-title')}>
                    {/* <div className={cx('Breadcrumbs')}>
                        <span className={cx('Breadcrumbs_name')} onClick={handleRedirect}>Group server 1</span>
                        <span className={cx('icon-next')}>{'>'}</span>
                        <span className={cx('Breadcrumbs_name-disable')}>Server 3</span>
                    </div> */}
                    {/* <Breadcrumbs pathCurrent={`/group/${id}`} /> */}

                    {/* button action */}
                    <div className={cx('wrap-btn-action')}>
                        <input className={cx('input-search')} placeholder="サーバー検索" onChange={handleSearch} />
                    </div>
                </div>
            </div>
            <div className={cx('scroll-outer-wrapper')}>
                <div className={cx('scroll-outer')}>
                    <div className={cx('wrap-table')} style={{ overflowX: loading ? 'hidden' : 'auto', height : 'calc(82vh - 40px)' }}>
                        <div className={cx('list-table')}>
                            <div className={cx('list-title-table')}>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>IPアドレス</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ステータス</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>Surveillance Gid</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>マシン名</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'glo-table-col-stop', 'text-left')}>
                                    停止中
                                </div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>停止中時間</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>作成日</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>スキャン時刻</div>
                            </div>

                            {!loading && (
                                <div className={cx('infinite-scroll-container')}>
                                    <InfiniteScroll
                                        dataLength={servers.length}
                                        next={fetchData}
                                        hasMore={servers.length >= totalServers ? false : true}
                                        loader={<h4>読み込み中...</h4>}
                                    >
                                        {servers.length > 0
                                            ? servers.map((server) => (
                                                  <div
                                                      className={cx('list-item-body-tr')}
                                                      key={server.id}
                                                  >
                                                      <div className={cx('glo-table-col', 'text-left')}>{server.ip_address}</div>
                                                      <div className={cx('glo-table-col', 'glo-table-col__status')}>
                                                          {getStatus(server.state)}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.groupSurveillance.group_name}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>{server.machine_name}</div>
                                                      <div className={cx('glo-table-col', 'glo-table-col-stop', 'text-left')}>
                                                          {server.suspended === 1 ? 'オフ' : 'オン'}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.suspension_time &&
                                                              moment(server.suspension_time).format('YYYY-MM-DD')}{' '}
                                                          <br />
                                                          {server.suspension_time &&
                                                              moment(server.suspension_time).format('HH:mm:ss')}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.created_at &&
                                                              moment(server.created_at).format('YYYY-MM-DD')}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {server.updated_at &&
                                                              moment(server.updated_at).format('YYYY-MM-DD')}{' '}
                                                          <br />
                                                          {server.updated_at &&
                                                              moment(server.updated_at).format('HH:mm:ss')}
                                                      </div>
                                                  </div>
                                              ))
                                            : totalServers !== null && (
                                                  <p className={cx('no-match')}>結果を見つけない</p>
                                              )}
                                    </InfiniteScroll>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServerUserList;
