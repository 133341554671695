import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './GroupServer.module.scss';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { useMyContext } from '~/context/store';
import moment from 'moment';
import Swal from 'sweetalert2';
import FormModal from '~/components/FormModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDebounce from '~/hooks/useDebounce';

const cx = classNames.bind(styles);

function GroupServer() {
    const [idsChecked, setIdsChecked] = useState([]);
    const [groupServers, setGroupServers] = useState([]);
    const { loading, setLoading, breadcrumbs, setBreadcrumbs } = useMyContext();

    const [callback, setCallback] = useState(false);
    const navigate = useNavigate();
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(16);
    const [totalGroupServers, setTotalGroupServers] = useState(null);
    const [search, setSearch] = useState();
    const searchDebounce = useDebounce(search, 600);

    const handleAddId = (e, id) => {
        e.stopPropagation();
        setIdsChecked([...idsChecked, id]);

        if (idsChecked.includes(id)) {
            setIdsChecked(idsChecked.filter((y) => y !== id));
        }
    };

    const handleRedirect = (id, name) => {
        setBreadcrumbs([
            { path: '/', breadcrumb: 'グループサーバー管理' },
            { path: `/group/${id}`, breadcrumb: name },
        ]);
        navigate(`/group/${id}`);
    };

    useEffect(() => {
        const getGroupServers = async () => {
            const params = {
                offset,
                limit,
            };
            const res = await api.get('api/groupSurveillances/filters', { params });
            if (res.status === 200) {
                setGroupServers([...groupServers, ...res.data.data.data]);
            }
        };
        offset >= 16 && getGroupServers();
    }, [offset]);

    useEffect(() => {
        const getGroupServer = async () => {
            setLoading(true);
            const params = {
                offset,
                limit,
                searchTerm: search,
            };
            const res = await api.get('api/groupSurveillances/filters', { params });

            if (res.status === 200) {
                setGroupServers(res.data.data.data);
                setTotalGroupServers(res.data.meta.total);
                setLoading(false);
            }
        };

        getGroupServer();
    }, [callback, searchDebounce]);

    const handleDeleteGroup = () => {
        Swal.fire({
            title: '確認',
            text: `グループサーバーを削除しますか？`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            cancelButtonText: 'キャンセル',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await Promise.all(
                    idsChecked.map(async (id) => {
                        await api.delete(`api/groupSurveillances/${id}`);
                    }),
                );
                setCallback(!callback);
                setIdsChecked([]);
                setOffset(0);
                Swal.fire('通知', `グループサーバーを削除しました`, 'success');
            }
        });
    };

    const handleAddGroupServer = () => {
        setIsOpenForm(true);
        setDataForm({
            type: 'add',
            titleForm: 'Add Group Server',
            text: '新規グループサーバー追加',
            data: [
                { value: '', title: 'グループ名', name: 'group_name', isShow: true },
                { value: '', title: '遅延', name: 'delay', isShow: true, min: 0, max: 180 },
                { value: 0, title: 'ステータス', name: 'state', isShow: false, min: 0, max: 3 },
            ],
        });
    };

    const handleEditGroupServer = () => {
        const groupServerActive = groupServers.find((ele) => ele.id === idsChecked[0]);
        setIsOpenForm(true);

        setDataForm({
            type: 'edit',
            titleForm: 'Edit Group Server',
            text: 'グループサーバー編集',
            id: idsChecked[0],
            data: [
                { value: groupServerActive.group_name, title: 'グループ名', name: 'group_name', isShow: true },
                { value: groupServerActive.delay, title: '遅延', name: 'delay', isShow: true, min: 0, max: 180 },
                { value: groupServerActive.state, title: 'ステータス', name: 'state', isShow: false, min: 0, max: 3 },
            ],
        });
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 16);
        }, 500);
    };

    const handleChangeSearch = (e) => {
        setOffset(0);
        setSearch(e.target.value);
    };

    const getStatus = (status) => {
        if (status === 0) {
            return <div className={cx('status-ok')}>OK</div>;
        } else if (status === 1) {
            return <div className={cx('status-stop')}>チェック中</div>;
        } else if (status === 2) {
            return <div className={cx('status-warning')}>警告</div>;
        } else if (status === 3) {
            return <div className={cx('status-error')}>エラー</div>;
        }
    };

    return (
        <>
            <div className={cx('Breadcrumbs_action')}>
                <div className={cx('Breadcrumbs')}></div>

                {/* button action */}
                <div className={cx('wrap-btn-action')}>
                    <input
                        className={cx('input-search')}
                        placeholder="グループサーバー検索"
                        onChange={handleChangeSearch}
                    />
                    <button className={cx('btn-action', 'btn-action-add')} onClick={handleAddGroupServer}>
                        <IoMdAddCircleOutline className={cx('btn-action__icon-add')} />
                        <span className={cx('btn-action__name', 'btn-action__name-add')}>新規グループサーバー追加</span>
                    </button>

                    <button
                        className={cx('btn-action', 'btn-action-edit')}
                        onClick={handleEditGroupServer}
                        disabled={idsChecked.length !== 1}
                    >
                        <MdModeEditOutline className={cx('btn-action__icon-edit')} />
                        <span className={cx('btn-action__name', 'btn-action__name-edit')}>編集</span>
                    </button>
                    <button
                        className={cx('btn-action', 'btn-action-delete')}
                        disabled={idsChecked.length < 1}
                        onClick={() => handleDeleteGroup()}
                    >
                        <AiFillDelete className={cx('btn-action__icon-delete')} />
                        <span className={cx('btn-action__name', 'btn-action__name-delete')}>削除</span>
                    </button>
                </div>
            </div>
            <div className={cx('scroll-outer-wrapper')}>
                <div className={cx('scroll-outer')}>
                    <div className={cx('wrap-table')} style={{ overflowX: loading ? 'hidden' : 'auto', height : 'calc(82vh - 40px)'}}>
                        <div className={cx('list-table')}>
                            <div className={cx('list-title-table')}>
                                <div className={cx('glo-table-col', 'glo-table-col-checkbox')}></div>
                                {/* <div className={cx('glo-table-col', 'glo-table-col-name')}>ID</div> */}
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>グループ名</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>遅延</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>ステータス</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>作成日</div>
                                <div className={cx('glo-table-col', 'glo-table-col-name', 'text-left')}>更新日</div>
                            </div>

                            {!loading && (
                                <div className={cx('infinite-scroll-container')}>
                                    <InfiniteScroll
                                        dataLength={groupServers.length}
                                        next={fetchData}
                                        hasMore={groupServers.length >= totalGroupServers ? false : true}
                                        loader={<h4>読み込み中...</h4>}
                                    >
                                        {groupServers.length > 0
                                            ? groupServers.map((group, index) => (
                                                  <div
                                                      className={cx('list-item-body-tr')}
                                                      key={group.id}
                                                      onClick={() => handleRedirect(group.id, group.group_name)}
                                                  >
                                                      <div
                                                          className={cx('glo-table-col-checkbox')}
                                                          onClick={(e) => handleAddId(e, group.id)}
                                                      >
                                                          <input
                                                              type="checkbox"
                                                              checked={idsChecked.includes(group.id)}
                                                          />
                                                      </div>
                                                      {/* <div className={cx('glo-table-col')}>{group.id}</div> */}
                                                      <div className={cx('glo-table-col', 'glo-table-col__groupname', 'text-left')}>
                                                          <span>{group.group_name}</span>
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>{group.delay}</div>
                                                      <div className={cx('glo-table-col', 'glo-table-col__status')}>
                                                          {getStatus(group.state)}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>
                                                          {group.created_at &&
                                                              moment(group.created_at).format('YYYY-MM-DD')}
                                                      </div>
                                                      <div className={cx('glo-table-col', 'text-left')}>{group.updated_at}</div>
                                                  </div>
                                              ))
                                            : totalGroupServers !== null && (
                                                  <p className={cx('no-match')}>結果を見つけない</p>
                                              )}
                                    </InfiniteScroll>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <FormModal
                isOpen={isOpenForm}
                setIsOpen={setIsOpenForm}
                data={dataForm}
                setData={setDataForm}
                callback={callback}
                setCallback={setCallback}
                setIdsChecked={setIdsChecked}
                setOffset={setOffset}
            />
        </>
    );
}

export default GroupServer;
