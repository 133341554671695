import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import logo from '~/assets/images/logo-page.png';
import { useMyContext } from '~/context/store';
import { loginUser, useAuthState, useAuthDispatch } from '../../context';
import { useNavigate } from 'react-router-dom';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const initialState = {
    email: '',
    password: '',
    err: '',
    success: '',
};

function Login() {
    const [user, setUser] = useState(initialState);
    const { loading, setLoading } = useMyContext();
    const dispatch = useAuthDispatch();

    const { email, password, err, success } = user;
    let navigate = useNavigate();

    const inputEmail = useRef();
    const inputPassword = useRef();

    // const auth = useSelector((state) => state.auth);
    // const { isLogged } = auth;

    // useEffect(() => {
    //     if (isLogged && localStorage.getItem('firstLogin')) {
    //         navigate('/');
    //     }
    // }, []);

    const handleInputEmail = (value) => {
        setUser({ ...user, email: value, err: '', success: '' });
    };

    const handleInputPassword = (value) => {
        setUser({ ...user, password: value, err: '', success: '' });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // 👇 Get input value
            handleSubmitLogin(event);
        }
    };

    // login
    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const emailInput = inputEmail.current.value;
        const passwordInput = inputPassword.current.value;
        const payload = { user_name: emailInput, password: passwordInput };

        const body = {
            user_name: payload.user_name,
            password: payload.password,
        };

        try {
            const response = await api.post('api/login', body);
            const { id, user_name, access_token, role } = response.data.data;

            if (response.data) {
                dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.data });
                localStorage.setItem('user', JSON.stringify({ id, user_name, role }));
                localStorage.setItem('accessToken', access_token);
                navigate('/');
            }
        } catch (error) {
            if (error.response.data.error.message.hasOwnProperty('password')) {
                setUser({ email: emailInput, password: '', err: error.response.data.error.message, success: '' });
                inputPassword.current.focus();
            } else if (error.response.data.error.message.hasOwnProperty('user_name')) {
                setUser({ email: '', password: passwordInput, err: error.response.data.error.message, success: '' });
                inputEmail.current.focus();
            } else {
                setUser({ email: '', password: '', err: error.response.data.error.message, success: '' });
                inputEmail.current.focus();
            }
        }
        setLoading(false);
    };

    return (
        <div className={cx('login-page')}>
            <div className={cx('wrapper-login')}>
                <div className={cx('login-container')}>
                    <form onSubmit={handleSubmitLogin}>
                        <img src={logo} alt="logo" className={cx('image-logo')} />
                        <div className={cx('mb-4', 'wrap-form')}>
                            <input
                                ref={inputEmail}
                                value={email}
                                type="text"
                                className={cx('wrapper-input')}
                                id="inputUsername"
                                placeholder="ユーザー名"
                                autoFocus
                                onChange={(e) => handleInputEmail(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                ref={inputPassword}
                                value={password}
                                className={cx('wrapper-input')}
                                id="inputPassword"
                                placeholder="パスワード"
                                onChange={(e) => handleInputPassword(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className="row">
                            <div className={cx('wrapper-error-message')}>
                                {err && (
                                    <p className={cx('error-message')}>
                                        {(err.hasOwnProperty('user_name') && err.user_name[0]) ||
                                            (err.hasOwnProperty('password') && err.password[0])}
                                    </p>
                                )}
                                {err && (
                                    <p className={cx('error-message')}>
                                        {!err.hasOwnProperty('user_name') && !err.hasOwnProperty('password') && err}
                                    </p>
                                )}
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-6 mb-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                    />
                                    <label className={cx('hn-label-checkbox')} htmlFor="flexCheckDefault">
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <div className="col-6 mb-4 d-flex justify-content-end">
                                <div className={cx('text-forgot-password')}>
                                    <a href="">Forgot Password?</a>
                                </div>
                            </div>
                        </div> */}
                        <div className={cx('wrapper-btn-submit')}>
                            <button
                                type="submit"
                                id="btn-submit-login"
                                className={cx('btn', 'btn-lg', 'btn-login')}
                                disabled={loading || (user.email === '' && user.password === '')}
                            >
                                ログイン
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
