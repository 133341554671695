import classNames from 'classnames/bind';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoMdClose } from 'react-icons/io';
import { useMyContext } from '~/context/store';
import api from '~/utils/main';
import styles from './FormModal.module.scss';

const cx = classNames.bind(styles);

function FormModal(props) {
    const { isOpen, setIsOpen, data, setData, callback, setCallback, setIdsChecked, setInfoUsers, setOffset } = props;
    const [error, setError] = useState('');
    const { loading, setLoading } = useMyContext();
    const [groupManages, setGroupManages] = useState([]);
    const [groupSurveillances, setGroupSurveillances] = useState([]);
    const [servers, setServers] = useState([]);
    const [isShowMenuFilterManage, setIsShowMenuFilterManage] = useState(false);
    const [isShowMenuGroupServer, setIsShowMenuGroupServer ] = useState(false);
    const [isShowMenuFilterSurveillances, setIsShowMenuFilterSurveillances] = useState(false);
    const [selectTypeRadio, setSelectTypeRadio] = useState(0); //0: url, 1: ip
    const [errorServer, setErrorServer] = useState('');

    const refDropdownManage = useRef();
    const refDropdownSurveillances = useRef();

    useEffect(() => {
        if (data.titleForm === 'Edit Monitor') {
            const indexUrl = data.data.findIndex((e) => e.name === 'url');
            data.data[indexUrl].value === null ? setSelectTypeRadio(1) : setSelectTypeRadio(0);
        }
        if (data.titleForm === 'Add Monitor') {
            const indexUrl = data.data.findIndex((e) => e.name === 'url');
            data.data[indexUrl].value === '' && setSelectTypeRadio(1);
        }
    }, [isOpen]);

    // useEffect(() => {
    //     if (data.titleForm === 'Add Server' || data.titleForm === 'Edit Server') {
    //         if (data.data[8].value === 0 || data.data[8].value === '') {
    //             const cloneData = [...data.data];

    //             cloneData[9].isShow = true;
    //             setData({ ...data, data: cloneData });
    //         }

    //         if (data.data[8].value === 1) {
    //             const cloneData = [...data.data];

    //             cloneData[9].isShow = false;
    //             setData({ ...data, data: cloneData });
    //         }
    //     }
    // }, [isOpen]);

    const handleClose = () => {
        setIsOpen(false);
        setError('');
        setData([]);
        setGroupManages([]);
        setGroupSurveillances([]);
        setServers([]);
        setIsShowMenuFilterManage(false);
        setErrorServer('');
        setIdsChecked([]);
        data.titleForm === 'Add User' && setInfoUsers([]);
        setSelectTypeRadio(0);
    };

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            handleClose();
        }
    }, []);

    const handleClickOutside = (event) => {
        if (refDropdownManage.current && !refDropdownManage.current.contains(event.target)) {
            setIsShowMenuFilterManage(false);
        }
        if (refDropdownSurveillances.current && !refDropdownSurveillances.current.contains(event.target)) {
            setIsShowMenuFilterSurveillances(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isShowMenuFilterManage, isShowMenuFilterSurveillances]);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    const handleChange = (e, name) => {
        let { value, min, max } = e.target;

        setError('');
        const cloneData = [...data.data];

        var index = cloneData.findIndex((p) => p.name === name);

        if (min || max) {
            cloneData[index].value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        } else {
            cloneData[index].value = value;
        }
        setData({ ...data, data: cloneData });
        if (data.titleForm === 'Add Server' || data.titleForm === 'Edit Server') {
            const indexSuspended = data.data.findIndex((e) => e.name === 'suspended');
            const indexSuspensionTime = data.data.findIndex((e) => e.name === 'suspension_time');

            if (data.data[indexSuspended].value === 0 || data.data[indexSuspended].value === '') {
                const cloneData = [...data.data];

                cloneData[indexSuspensionTime].isShow = true;
                setData({ ...data, data: cloneData });
            }

            if (data.data[indexSuspended].value === 1) {
                const cloneData = [...data.data];

                cloneData[indexSuspensionTime].isShow = false;
                setData({ ...data, data: cloneData });
            }
        }
    };

    useEffect(() => {
        const getGroupManages = async () => {
            const res = await api.get('api/groupManages');

            if (res.status === 200) {
                setGroupManages(res.data.data);
                const dataClone = [...data.data];
                dataClone.map((ele) => {
                    if (ele.name === 'manage_gid') {
                        ele.value = res.data.data[0].id;
                    }
                });
                setData({ ...data, data: dataClone });
            }
        };

        const getGroupSurveillances = async () => {
            const res = await api.get('api/groupSurveillances');

            if (res.status === 200) {
                setGroupSurveillances(res.data.data);

                const dataClone = [...data.data];
                dataClone.map((ele) => {
                    if (ele.name === 'surveillance_gid') {
                        ele.value = res.data.data[0].id;
                    }
                });
                setData({ ...data, data: dataClone });
            }
        };

        const getGroupManagesEdit = async () => {
            const res = await api.get('api/groupManages');

            if (res.status === 200) {
                setGroupManages(res.data.data);
            }
        };
        const getGroupSurveillancesEdit = async () => {
            const res = await api.get('api/groupSurveillances');

            if (res.status === 200) {
                setGroupSurveillances(res.data.data);
            }
        };

        const getServers = async () => {
            const res = await api.get('api/servers');

            if (res.status === 200) {
                setServers(res.data.data);
            }
        };

        if (data.titleForm === 'Edit Info User') {
            const getGroup = async () => {
                const res = await api.get('api/groupManages');

                if (res.status === 200) {
                    setGroupManages(res.data.data);
                }
            };
            getGroup();
        }

        if (data.titleForm === 'Add User') {
            const dataClone = [...data.data];
            
            dataClone.map((ele) => {
                if (ele.name === 'manage_gid' && ele.type == 'select') {
                    getGroupManages();
                }
            });
        }

        if (data.titleForm === 'Add Server') {
            const getGroup = async () => {
                const res = await api.get('api/groupManages');

                if (res.status === 200) {
                    setGroupManages(res.data.data);
                    const dataClone = [...data.data];

                    dataClone.map((ele) => {
                        if (ele.name === 'manage_gid') {
                            ele.value = [];
                        }
                    });
                    setData({ ...data, data: dataClone });
                }
            };
            getGroup();
            // getGroupSurveillancesEdit();
            getGroupSurveillances();
        }
        if (data.titleForm === 'Edit Server') {
            getGroupManagesEdit();
            getGroupSurveillancesEdit();
        }

        // if (data.titleForm === 'Add Monitor') {
        //     getServers();
        // }

        // if (data.titleForm === 'Edit Monitor') {
        //     getServers();
        // }
    }, [data.titleForm]);

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        //Call api Edit Info User
        if (data.titleForm === 'Edit Info User') {
            const body = {};

            data.data.map((ele) => {
                body[ele.name] = ele.value;
            });

            try {
                setLoading(true);
                const res = await api.put(`api/users/${data.id}`, body);
                if (res.status === 200) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Add User
        if (data.titleForm === 'Add User') {
            const body = {};

            data.data.map((ele) => {
                body[ele.name] = ele.value;
            });

            try {
                setLoading(true);
                const res = await api.post(`api/users`, body);
                if (res.status === 201) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
                // setLoading(false)
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Add Group Server
        if (data.titleForm === 'Add Group Server') {
            const body = {};

            data.data.map((ele) => {
                body[ele.name] = ele.value;
            });

            try {
                setLoading(true);
                const res = await api.post(`api/groupSurveillances`, body);
                if (res.status === 201) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Edit Group Server
        if (data.titleForm === 'Edit Group Server') {
            const body = {};

            data.data.map((ele) => {
                body[ele.name] = ele.value;
            });

            try {
                setLoading(true);
                const res = await api.put(`api/groupSurveillances/${data.id}`, body);
                if (res.status === 200) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Add Server
        if (data.titleForm === 'Add Server') {
            const body = {};
            
            if (data.data[8].value === 0) {
                data.data.map((ele) => {
                    body[ele.name] = ele.value;
                });
            } else {
                const cloneData = [...data.data];

                const filterSuppendTime = cloneData.filter((e) => e.name !== 'suspension_time');

                filterSuppendTime.map((ele) => {
                    body[ele.name] = ele.value;
                });
            }
            console.log(body)
            try {
                setLoading(true);
                const res = await api.post(`api/servers`, body);
                if (res.status === 201) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Edit  Server
        if (data.titleForm === 'Edit Server') {
            const body = {};

            if (data.data[8].value === 0) {
                data.data.map((ele) => {
                    body[ele.name] = ele.value;
                });
            } else {
                const cloneData = [...data.data];

                const filterSuppendTime = cloneData.filter((e) => e.name !== 'suspension_time');

                filterSuppendTime.map((ele) => {
                    body[ele.name] = ele.value;
                });
            }

            try {
                setLoading(true);
                const res = await api.put(`api/servers/${data.id}`, body);
                if (res.status === 200) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Add Monitor
        if (data.titleForm === 'Add Monitor') {
            const body = {};

            let dataClone = [...data.data];
            let dataBody;

            if (selectTypeRadio === 0) {
                dataBody = dataClone.filter((ele) => ele.name !== 'ip_address' && ele.name !== 'port_id');
            } else {
                dataBody = dataClone.filter((ele) => ele.name !== 'url');
            }

            dataBody.map((ele) => {
                body[ele.name] = ele.value;
            });

            const type = selectTypeRadio === 0 ? 'url' : 'ip';
            setLoading(true);

            try {
                const res = await api.post(`api/monitores/${type}`, body);
                if (res.status === 201) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
                // setLoading(false)
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Edit Monitor
        if (data.titleForm === 'Edit Monitor') {
            const body = {};

            let dataClone = [...data.data];
            let dataBody;

            if (selectTypeRadio === 0) {
                dataBody = dataClone.filter((ele) => ele.name !== 'ip_address' && ele.name !== 'port_id');
            } else {
                dataBody = dataClone.filter((ele) => ele.name !== 'url');
            }

            dataBody.map((ele) => {
                body[ele.name] = ele.value;
            });

            const type = selectTypeRadio === 0 ? 'url' : 'ip';

            try {
                setLoading(true);
                const res = await api.put(`api/monitores/${type}/${data.id}`, body);
                if (res.status === 200) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Add Group Server
        if (data.titleForm === 'Add Groups Manage') {
            const body = {};

            data.data.map((ele) => {
                body[ele.name] = ele.value;
            });

            try {
                setLoading(true);
                const res = await api.post(`api/groupManages`, body);
                if (res.status === 201) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setError(error.response.data.error.message);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setLoading(false);
            }
        }

        //Call api Edit Group Server
        if (data.titleForm === 'Edit Groups Manage') {
            const body = {};

            data.data.map((ele) => {
                body[ele.name] = ele.value;
            });

            try {
                setLoading(true);
                const res = await api.put(`api/groupManages/${data.id}`, body);
                if (res.status === 200) {
                    setOffset(0);
                    setCallback(!callback);
                    handleClose();
                }
            } catch (error) {
                setLoading(false);
                if (error.response.status === 500) {
                    setErrorServer('ERROR 500');
                }
                setError(error.response.data.error.message);
            }
        }
    };

    const getNameInput = () => {
        if (data.type === 'edit') {
            return '保存';
        } else if (data.type === 'add') {
            return '作成';
        }
    };

    const getTypeInput = (name, type) => {
        if (name === 'mail_address') {
            return 'email';
        } else if (type === 'select') {
            return 'select';
        } else if (name === 'suspension_time') {
            return 'date';
        } else if (
            name === 'can_notify' ||
            name === 'stopped' ||
            name === 'state' ||
            name === 'delay' ||
            name === 'suspended'
        ) {
            return 'number';
        } else {
            return 'text';
        }
    };

    const handleChangeManageGid = (e, name) => {
        const dataClone = [...data.data];

        dataClone.map((ele) => {
            if (ele.name === 'manage_gid') {
                ele.value = +e.target.value;
            }
        });
        setData({ ...data, data: dataClone });
    };

    const handleChangeSurveillanceGid = (e, name) => {
        const dataClone = [...data.data];

        dataClone.map((ele) => {
            if (ele.name === 'surveillance_gid') {
                ele.value = +e.target.value;
            }
        });
        setData({ ...data, data: dataClone });
    };

    const handleChangeGroupServer = (e, name) => {
        const dataClone = [...data.data];

        dataClone.map((ele) => {
            if (ele.name === 'surveillance_gid') {
                ele.value = +e.target.value;
            }
        });
        setData({ ...data, data: dataClone });
    }

    const handleChangeServer = (e, name) => {
        const dataClone = [...data.data];

        dataClone.map((ele) => {
            if (ele.name === 'server_id') {
                ele.value = +e.target.value;
            }
        });
        setData({ ...data, data: dataClone });
    };

    const handleSelectManage = (id) => {
        const dataClone = [...data.data];

        dataClone.map((ele) => {
            if (ele.name === 'manage_gid') {
                if (!ele.value.includes(id)) {
                    ele.value = [...ele.value, id];
                } else {
                    ele.value = ele.value.filter((e) => e !== id);
                }
            }
        });

        setData({ ...data, data: dataClone });
    };

    const handleSelectSurveillances = (id) => {
        const dataClone = [...data.data];

        dataClone.map((ele) => {
            if (ele.name === 'surveillance_gid') {
                if (!ele.value.includes(id)) {
                    ele.value = [...ele.value, id];
                } else {
                    ele.value = ele.value.filter((e) => e !== id);
                }
            }
        });

        setData({ ...data, data: dataClone });
    };

    const getMenuSelectOption = (info) => {
        if (info.name === 'manage_gid' && (data.titleForm === 'Edit Server' || data.titleForm === 'Add Server')) {
            return (
                <div className={cx('dropdown')} ref={refDropdownManage}>
                    <button
                        type="button"
                        id="menuFilter"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className={cx('btn-select')}
                        onClick={() => setIsShowMenuFilterManage(!isShowMenuFilterManage)}
                    >
                        <div>グループ管理名選択</div>
                        <div className={cx('btn-filter__icon')}>
                            <IoIosArrowDown />
                        </div>
                    </button>

                    {isShowMenuFilterManage && (
                        <ul
                            className={cx('dropdown-menu')}
                            id={cx('dropdown-menu')}
                            aria-labelledby="menuFilter"
                            onClick={() => setIsShowMenuFilterManage(true)}
                        >
                            {groupManages.map((group) => (
                                <li
                                    className={cx('row-checkbox')}
                                    key={group.id}
                                    onClick={() => handleSelectManage(group.id)}
                                >
                                    <input
                                        id={`input-chkbox-${group.id}`}
                                        type="checkbox"
                                        checked={info.value.includes(group.id)}
                                    />
                                    <label
                                        htmlFor={`input-chkbox-${group.id}`}
                                        onClick={() => handleSelectManage(group.id)}
                                        className={cx('row-checkbox-name')}
                                    >
                                        {group.group_name}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            );
        } else if (info.name === 'server_id') {
            return (
                <select onChange={(e) => handleChangeServer(e, info.name)} value={info.value}>
                    {servers.map((server) => (
                        <option value={server.id} key={server.id}>
                            {server.server_name}
                        </option>
                    ))}
                </select>
            );
        }

        if (info.name === 'surveillance_gid' && (data.titleForm === 'Edit Server' || data.titleForm === 'Add Server')) {
            return (
                <select onChange={(e) => handleChangeGroupServer(e, info.name)} value={info.value}>
                    {groupSurveillances.map((server) => (
                        <option value={server.id} key={server.id}>
                            {server.group_name}
                        </option>
                    ))}
                </select>
            );
        }
        if (info.name === 'manage_gid' && info.type ==  'select' && (data.titleForm === 'Edit Info User' || data.titleForm === 'Add User')) {
            return (
                <select onChange={(e) => handleChangeManageGid(e, info.name)} value={info.value}>
                    {groupManages.map((group) => (
                        <option value={group.id} key={group.id}>
                            {group.group_name}
                        </option>
                    ))}
                </select>
            );
        }
    };

    const handleChangeRadioUrl = (e) => {
        setSelectTypeRadio(+e.target.value);
        setError();
    };

    const handleChangeRadioIP = (e) => {
        setSelectTypeRadio(+e.target.value);
        setError();
    };

    const filterData = (dataForm) => {
        if (data?.titleForm === 'Add Monitor' || data?.titleForm === 'Edit Monitor') {
            //URL
            if (selectTypeRadio === 0) {
                return dataForm.filter((ele) => ele.name !== 'ip_address' && ele.name !== 'port_id');
            } else {
                return dataForm.filter((ele) => ele.name !== 'url');
            }
        } else {
            return dataForm;
        }
    };

    const handleKeyDown = (event, name) => {
        if (
            name === 'can_notify' ||
            name === 'stopped' ||
            name === 'state' ||
            name === 'delay' ||
            name === 'suspended'
        ) {
            const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
            if (exceptThisSymbols.includes(event.key)) {
                event.preventDefault();
            }
        }

        if (event.key === 'Enter') {
            handleSubmitForm(event);
        }
    };

    return (
        <>
            {isOpen && <div className={cx('wrap-space-close')} onClick={handleClose}></div>}
            <div className={cx('Modal', `${isOpen ? 'show' : ''}`)}>
                <div className={cx('wrap-close')} onClick={handleClose}>
                    <IoMdClose />
                </div>
                <div className={cx('content')}>
                    <div className={cx('content-header')}>{data?.text}</div>

                    {(data.titleForm === 'Edit Monitor' || data.titleForm === 'Add Monitor') && (
                        <div className={cx('wrap-radio')}>
                            <div className={cx('wrap-radio-input')}>
                                <input
                                    id="radio-1"
                                    type="radio"
                                    checked={selectTypeRadio === 0}
                                    value="0"
                                    onChange={handleChangeRadioUrl}
                                />
                                <label htmlFor="radio-1">URL</label>
                            </div>
                            <div className={cx('wrap-radio-input')}>
                                <input
                                    id="radio-2"
                                    type="radio"
                                    checked={selectTypeRadio === 1}
                                    value="1"
                                    onChange={handleChangeRadioIP}
                                />
                                <label htmlFor="radio-2">IPアドレス</label>
                            </div>
                        </div>
                    )}
                    <div className={cx('content-body')}>
                        {filterData(data?.data)?.map((ele, index) => (
                            <Fragment key={ele.name}>
                                {ele.isShow ? (
                                    <div className={cx('content-body__item', `${index <= 1 ? 'row-data__first' : ''}`)}>
                                        <div className={cx('row-data')}>
                                            <div className={cx('wrap-title')}>
                                                <label>{ele.title}</label>
                                                <span>*</span>
                                            </div>
                                            {ele.type === 'select' ? (
                                                getMenuSelectOption(ele)
                                            ) : (
                                                <input
                                                    type={getTypeInput(ele.name)}
                                                    autoFocus={index === 0}
                                                    value={ele.value}
                                                    onChange={(e) => handleChange(e, ele.name)}
                                                    min={ele.hasOwnProperty('min') ? ele.min : ''}
                                                    max={ele.hasOwnProperty('max') ? ele.max : ''}
                                                    required
                                                    placeholder={ele.placeholder}
                                                    onKeyDown={(e) => handleKeyDown(e, ele.name)}
                                                />
                                            )}
                                            <span className={cx('msg-error')}>
                                                {error && error.hasOwnProperty(ele.name) ? error[ele.name][0] : ''}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Fragment>
                        ))}

                        <div className={cx('wrap-btn-submit')}>
                            <button type="submit" onClick={handleSubmitForm}>
                                {getNameInput()}
                            </button>
                        </div>
                        <div className={cx('msg-error-server')}>{errorServer}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormModal;
