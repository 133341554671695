// Lib
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

// Components
import styles from './DefaultLayout.module.scss';
import Sidebar from '~/layouts/components/Sidebar';
import Header from '~/layouts/components/Header';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {

    const  [show, setToggleNavBarSp] = useState(false);

    const handleClickToggle = () => {
        setToggleNavBarSp(show => !show);
    }

    return (
        <div className={cx('wrapper')}>
            <Header />
            <div className={cx('container-body')}>
                <div className={cx('wrapper-body')}>
                    <div className={cx('row', 'glo-row')}>
                        <div className={cx('glo-layout-sidebar', `${show == true ? 'active' : ''}`)}>
                            <Sidebar />
                            <div onClick={() => handleClickToggle()} className={cx('toggle-icon-sp', `${show == true ? 'active' : ''}`)}></div>
                        </div>
                        <div className={cx('glo-layout-content')}>
                            <div className={cx('spacer-header')}></div>
                            <div className={cx('content', 'glo-width-content')}>{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DefaultLayout;
