import React from 'react'
import TopBarProgress from "react-topbar-progress-indicator";

function Loading() {
    TopBarProgress.config({
        barColors: {
          "0": "#0052cc",
          "0.5": "#0052cc",
          "1": "#0052cc"
        },
        shadowBlur: 10
      });
      
    return ( <TopBarProgress /> );
}

export default Loading;