import classNames from 'classnames/bind';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '~/context/store';
import styles from './BreadcrumbsUser.module.scss';

const cx = classNames.bind(styles);

function BreadcrumbsUser(props) {
    const { breadcrumbs, setBreadcrumbs } = useMyContext();
    const { pathCurrent } = props;
    const navigate = useNavigate();

    const handleClickBreadcrumb = (index, path) => {
        setBreadcrumbs(breadcrumbs.slice(0, index + 1));
        navigate(`${path}`);
    };

    return (
        <div className={cx('Breadcrumbs')}>
            {breadcrumbs.map((ele, index) => (
                <Fragment key={index}>
                    <span
                        className={cx(
                            'Breadcrumbs_name',
                            `${pathCurrent === ele.path ? 'Breadcrumbs_name-disable' : ''}`,
                        )}
                        onClick={() => handleClickBreadcrumb(index, ele.path)}
                    >
                        {ele.breadcrumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && <span className={cx('icon-next')}>{'>'}</span>}
                </Fragment>
            ))}
        </div>
    );
}

export default BreadcrumbsUser;
