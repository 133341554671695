import api from '~/utils/main';

export async function loginUser(dispatch, loginPayload) {
    const body = {
        user_name: loginPayload.user_name,
        password: loginPayload.password,
    };

    
    try {
        const response = await api.post('api/login', body);
        const { id, user_name, access_token, role } = response.data.data;

        if (response.data) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.data });
            localStorage.setItem('user', JSON.stringify({ id, user_name, role }));
            localStorage.setItem('accessToken', access_token);
        }
        return response;
    } catch (error) {
        throw error;
    }
}

export async function logout(dispatch) {
    const res = await api.post('api/logout');

    if (res.status === 201) {
        dispatch({ type: 'LOGOUT' });
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
    }
}
