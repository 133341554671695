// Context/reducer.js

let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
let token = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';

export const initialState = {
    userDetails: '' || user,
    token: '' || token,
    isAuthenticated: token !== '',
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                userDetails: action.payload,
                token: action.payload.access_token,
                isAuthenticated: true,
            };
        case 'LOGOUT':
            return {
                isAuthenticated: false,
                userDetails: '',
                token: '',
            };

        // case 'LOGIN_ERROR':
        //     return {
        //         ...initialState,
        //         loading: false,
        //         errorMessage: action.error,
        //     };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
