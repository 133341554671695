import classNames from 'classnames/bind';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { AiOutlineUser } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { MdMonitor } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '~/context';

import styles from './Sidebar.module.scss';
const cx = classNames.bind(styles);

const listMenu = [
    {
        icon: <AiOutlineUnorderedList />,
        name: '監視グループ',
        path: '/',
    },
    {
        icon: <FiUsers />,
        name: '管理グループ',
        path: '/groupManages',
    },
    {
        icon: <AiOutlineCloudServer />,
        name: 'サーバー一覧',
        path: '/serverList',
    },
    {
        icon: <AiOutlineUser />,
        name: 'ユーザー一覧',
        path: '/userList',
    },
    {
        icon: <MdMonitor />,
        name: '監視先',
        path: '/monitoring',
    },
];

const listMenuUser = [
    {
        icon: <MdMonitor />,
        name: '監視先',
        path: '/',
    },
    {
        icon: <AiOutlineCloudServer />,
        name: 'サーバー一覧',
        path: '/serverUserList',
    }
];

function Sidebar() {
    const navigate = useNavigate();
    const userDetails = useAuthState();

    const handleRidirect = (path) => {
        navigate(path);
    };

    return (
        <div className={cx('wrapper-sidebar', 'glo-position-relative')}>
            <div className={cx('glo-btnMenu-mobile')}>
                <div className={cx('menu-custom-witdth-click')}></div>
            </div>
            <div>
                <div>
                    <div className={cx('sidebar-menu')}>
                        <div className={cx('sidebar-menu-list')}>
                            {(userDetails.userDetails.role === 'Admin' ? listMenu : listMenuUser).map((item, index) => (
                                <div
                                    className={cx(
                                        'sidebar-menu-list__item',
                                        window.location.pathname === item.path ? 'item-active' : '',
                                    )}
                                    key={index}
                                    onClick={() => handleRidirect(item.path)}
                                >
                                    <span
                                        className={cx(
                                            'sidebar-menu-list__item-icon',
                                            window.location.pathname === item.path ? 'icon-active' : '',
                                        )}
                                    >
                                        {item.icon}
                                    </span>
                                    <span className={cx('sidebar-menu-list__item-text')}>{item.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Sidebar;
